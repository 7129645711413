<mat-expansion-panel class="mat-elevation-z0 w-full" togglePosition="before" [expanded]="true">
  <mat-expansion-panel-header>
    @let _sectionData = sectionData();

    <mat-panel-title class="select-none text-2xl">
      {{ _sectionData.name }}
      <mat-icon class="ml-2">{{ _sectionData.icon }}</mat-icon>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <ng-content />
</mat-expansion-panel>
