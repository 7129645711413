import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { AxisRange, GraphCanvasDimensions } from '@astrion-webtools/graph';
import { MaterialModule } from '@modules/material.module';

const ICON_SIZE = 16;

export interface HarmonicData {
  frequency: number;
  rank: number;
  peakIndex: number;
  color: string;
}

@Component({
  selector: 'app-harmonics-markers',
  imports: [CommonModule, MaterialModule],
  templateUrl: './harmonics-markers.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HarmonicsMarkersComponent {
  public harmonics = input.required<HarmonicData[]>();
  public canvasDimensions = input.required<GraphCanvasDimensions>();
  public frequenciesRange = input.required<AxisRange>();

  public markerIconStyle = {
    height: `${ICON_SIZE}px`,
    width: `${ICON_SIZE}px`,
    'font-size': `${ICON_SIZE}px`,
    'font-weight': '600',
  };

  public markerRankStyle = {
    'font-size': `${ICON_SIZE}px`,
  };

  public axisOverlayStyle = computed(() => {
    const dimensions = this.canvasDimensions();

    return {
      width: `${dimensions.width}px`,
      height: '40px',
      top: `${dimensions.y + dimensions.height}px`,
      left: `${dimensions.x}px`,
    };
  });

  public harmonicMarkers = computed(() => {
    const visibleFrequencyRange = this.frequenciesRange();

    const visibleHarmonics = this.harmonics().filter(
      harmonic => harmonic.frequency > visibleFrequencyRange.min && harmonic.frequency < visibleFrequencyRange.max
    );

    const overlayWidth = this.canvasDimensions().width;

    const visibleFrequenciesSpan = visibleFrequencyRange.max - visibleFrequencyRange.min;
    const halfIconSize = ICON_SIZE / 2;

    return visibleHarmonics.map(harmonic => {
      const ratio = (harmonic.frequency - visibleFrequencyRange.min) / visibleFrequenciesSpan;

      return {
        frequency: harmonic.frequency,
        rank: harmonic.rank,
        icon: harmonic.peakIndex < 0 ? 'close' : 'north',
        color: harmonic.color,
        containerStyle: {
          position: 'absolute',
          top: '0px',
          left: `${ratio * overlayWidth - halfIconSize}px`,
          width: `${ICON_SIZE}px`,
          height: '100%',
        },
      };
    });
  });
}
