import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  output,
  signal,
  viewChild,
  viewChildren,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatRipple } from '@angular/material/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ContextMenuComponent } from '@components/context-menu/context-menu.component';
import { NameEditionInputComponent } from '@components/name-edition-input/name-edition-input.component';
import { ScrollableBorderedContainerComponent } from '@components/scrollable-bordered-container/scrollable-bordered-container.component';
import { authFeature } from '@features/auth/shared/store/auth.feature';
import {
  PercentageValidationFlagComponent,
  ShannonValidationFlagComponent,
  TimeSaturationValidationFlagComponent,
} from '@features/data-validation/components/validation-flag/validation-flag.component';
import { ValidationFlagsColorHeadbandComponent } from '@features/data-validation/components/validation-flags-color-headband/validation-flags-color-headband.component';
import { ComputeMenuComponent } from '@features/sensor-signals/components/compute-menu/compute-menu.component';
import { SensorSignalsService } from '@features/sensor-signals/shared/services/sensor-signals.service';
import { SensorId } from '@features/sensors/shared/interfaces/sensor.interface';
import {
  AStrionSignal,
  AStrionSignalId,
  AStrionSignalWithStatus,
  getDuration,
} from '@features/signals/shared/interface/astrion-signal.interface';
import { MaterialModule } from '@modules/material.module';
import { Store } from '@ngrx/store';
import { DialogService } from '@services/dialog.service';
import { DragAndDropComponent } from '@shared/components/drag-and-drop/drag-and-drop.component';
import { ComputationStatus } from '@shared/interfaces/processing-status';

import { astrionSignalPageSize } from '@features/signals/shared/interface/astrion-signal-page-size';
import { SignalIconComponent } from '../signal-icon/signal-icon.component';
import { FilterDialogService } from '@services/filter-dialog.service';
import { DateFilterComponent } from '@components/filters/filter/date-filter.component';
import { DateFilterModel } from '@shared/interfaces/filter-model';
import moment from 'moment';

@Component({
  selector: 'app-sensor-signals',
  templateUrl: './sensor-signals.component.html',
  imports: [
    CommonModule,
    MaterialModule,
    ContextMenuComponent,
    ComputeMenuComponent,
    NameEditionInputComponent,
    ScrollableBorderedContainerComponent,
    DragAndDropComponent,
    SignalIconComponent,
    ValidationFlagsColorHeadbandComponent,
    PercentageValidationFlagComponent,
    ShannonValidationFlagComponent,
    TimeSaturationValidationFlagComponent,
    ReactiveFormsModule,
    DateFilterComponent,
  ],
  providers: [DialogService, SensorSignalsService, FilterDialogService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('addColumn', [
      transition(':enter', [
        style({ transform: 'translate(-100%)' }),
        animate('100ms', style({ transform: 'translate(0%)' })),
      ]),
    ]),
  ],
})
export class SensorSignalsComponent {
  loading = input<boolean>(false);
  sensorId = input.required<SensorId>();
  navigateToGraph = output<void>();
  navigateToReport = output<AStrionSignal>();

  matRipples = viewChildren<MatRipple>(MatRipple);
  rightTrigger = viewChild<MatMenuTrigger>('rightTrigger');

  canRecompute = this.store.selectSignal(authFeature.selectCanRecompute);
  canEdit = this.store.selectSignal(authFeature.selectCanEdit);
  currentlyEditedNameId = signal<AStrionSignalId | null>(null);

  private _sort = viewChild.required(MatSort);
  private _paginator = viewChild(MatPaginator);
  public dateFilter = signal<DateFilterModel | undefined>(undefined);

  public items = this.sensorSignalsService.selectCurrentSensorSignalsWithStatuses();
  public filteredItems = computed(() => {
    const dateFilter = this.dateFilter();
    return !dateFilter
      ? this.items()
      : this.items().filter(item => {
          if (!item.date) return false;
          const itemDate = moment(item.date).startOf('day'); // Remove time part
          return itemDate.isSameOrAfter(dateFilter.min, 'day') && itemDate.isSameOrBefore(dateFilter.max, 'day');
        });
  });

  public highligtedItem = signal<AStrionSignal | null>(null);
  private _menuOpened = false;
  private _mouseEnteredDuringMenuOpened = false;

  // drag and drop
  isDragging = signal<boolean>(false);
  isEmpty = computed(() => this.items().length <= 0);
  showDragging = computed(() => this.isDragging() || (!this.loading() && this.isEmpty()));

  dates = computed(() =>
    this.items()
      .map(i => i.date)
      .filter(date => date !== undefined)
  );
  nItems = computed(() => this.items().length);
  nCompletedSignals = computed(() => {
    const items = this.items();
    return items.reduce(
      (count, item) => (item.status?.computationStatus === ComputationStatus.Succeeded ? count + 1 : count),
      0
    );
  });
  computationPercent = computed(() => (100 * this.nCompletedSignals()) / this.nItems());

  public hasPaginator = computed(() => this.items.length > 50);

  datasource = computed(() => {
    const sort = this._sort();
    const paginator = this._paginator();
    const filteredItems = this.filteredItems();
    if (sort && paginator && filteredItems.length > 0) {
      const datasource = new MatTableDataSource(filteredItems);
      datasource.sort = sort;
      datasource.sortingDataAccessor = (item: AStrionSignalWithStatus, sortHeaderId: string) => {
        switch (sortHeaderId) {
          case 'status':
            return item.status?.computationStatus as number;
          case 'name':
            return item.name;
          case 'samplesCount':
            return item.samplesCount;
          case 'samplingFrequency':
            return item.samplingFrequency;
          case 'date':
            return item.date?.getTime() ?? Date.now();
          case 'duration':
            return this.getDuration(item);
          default:
            return 0;
        }
      };
      datasource.paginator = paginator;
      return datasource;
    } else {
      return [];
    }
  });

  public validationFlagsCollapsed = signal(true);
  private columns = ['status', 'name', 'date', 'validationFlags', 'button'];

  private validationFlagColumns = ['timeSaturation', 'shannonTest', 'nonStationnarity'];

  public displayedColumns = computed(() => {
    return this.validationFlagsCollapsed()
      ? this.columns
      : [...this.columns.slice(0, 4), ...this.validationFlagColumns, ...this.columns.slice(4)];
  });

  public nonStationnaritySelected = new FormControl(false);

  private duplicateDateSignalIds = this.sensorSignalsService.selectCurrentSensorDuplicateDateSignalIds();

  constructor(
    private dialogService: DialogService,
    private sensorSignalsService: SensorSignalsService,
    private store: Store
  ) {}

  public rightClickMenuPosition = { x: '0', y: '0' };

  filterDates(dateFilter: DateFilterModel | undefined) {
    this.dateFilter.set(dateFilter);
  }

  public onFilesDrop(files: File[]) {
    this.uploadFiles(files);
  }

  public cancelEditName() {
    this.currentlyEditedNameId.set(null);
  }

  public editName(item: AStrionSignal) {
    this.currentlyEditedNameId.set(item.id);
  }

  public changeName(item: AStrionSignal, newName: string) {
    this.sensorSignalsService.changeSignalName(item, newName);
    this.currentlyEditedNameId.set(null);
  }

  public onClick(event: MouseEvent, item: AStrionSignal, index: number) {
    this.matRipples()[index]?.launch({ centered: true });
    if (event.ctrlKey) {
      this.openInNew(item);
    } else {
      this.navigateToReport.emit(item);
    }
  }

  public onRightClick(event: MouseEvent, item: AStrionSignal) {
    event.preventDefault();
    event.stopPropagation();
    this.rightClickMenuPosition.x = event.clientX + 'px';
    this.rightClickMenuPosition.y = event.clientY + 'px';
    this.rightTrigger()!.menuData = { item };
    this.rightTrigger()!.openMenu();
  }

  public menuOpened() {
    this._menuOpened = true;
  }

  public menuClosed() {
    this._menuOpened = false;
    if (!this._mouseEnteredDuringMenuOpened) {
      this.highligtedItem.set(null);
    }
    this._mouseEnteredDuringMenuOpened = false;
  }

  public mouseEnterItem(item: AStrionSignal) {
    this.highligtedItem.set(item);
    if (this._menuOpened) {
      this._mouseEnteredDuringMenuOpened = true;
    }
  }

  public mouseLeaveContent() {
    if (!this._menuOpened) {
      this.highligtedItem.set(null);
    }
  }

  getDuration = getDuration;

  onDragStart() {
    this.isDragging.set(true);
  }

  onDragStop() {
    this.isDragging.set(false);
  }

  async openUploadDialog() {
    const files = await this.dialogService.uploadFiles();
    if (files !== undefined && files !== null && files.length > 0) {
      this.uploadFiles(files);
    }
  }

  toggleValidationFlags() {
    this.validationFlagsCollapsed.update(value => !value);
    if (this.validationFlagsCollapsed()) {
      this.nonStationnaritySelected.setValue(false);
    }
  }

  visualize() {
    this.sensorSignalsService.visualizeNonStationnarityCurve(this.sensorId());
    this.nonStationnaritySelected.setValue(false);
    this.navigateToGraph.emit();
  }

  hasDuplicatedDate(id: AStrionSignalId) {
    return this.duplicateDateSignalIds().some(i => i === id);
  }

  flagDataValidationAsDirty() {
    this.sensorSignalsService.setSensorDataValidationAsDirty(this.sensorId());
  }

  flagPeakIdentificationAsDirty() {
    this.sensorSignalsService.setSensorPeakIdentificationAsDirty(this.sensorId());
  }

  trackSignalId(index: number, item: AStrionSignal): string {
    return `${item.id}`;
  }

  refreshSignals() {
    this.sensorSignalsService.fetchSignals(this.sensorId());
  }

  openInNew(item: AStrionSignal) {
    window.open(`/signals/report/${item.id}`, '_blank');
  }

  downloadSignal(item: AStrionSignal) {
    this.sensorSignalsService.downloadSignal(item.id);
  }

  delete(item: AStrionSignal) {
    this.sensorSignalsService.deleteSignal(item);
  }

  pageSize = astrionSignalPageSize;

  private uploadFiles(files: File[]) {
    this.sensorSignalsService.uploadFiles(files, this.sensorId());
  }
}
