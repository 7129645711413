import { Component, computed, output } from '@angular/core';
import { dataValidationFeature } from '../../store/data-validation.feature';
import { Store } from '@ngrx/store';
import {
  DataValidationSectionTileComponent,
  SectionTileData,
} from './data-validation-section-tile/data-validation-section-tile.component';
import { ValidationStepStatus } from '../../interface/validation-step-status';
import { MaterialModule } from '@modules/material.module';
import { DATA_VALIDATION_SECTION } from '@features/signal-report/shared/interface/report-structure';

interface ExtendedTileData extends SectionTileData {
  anchorId: string;
}

@Component({
  selector: 'app-data-validation-summary',
  imports: [DataValidationSectionTileComponent, MaterialModule],
  templateUrl: './data-validation-summary.component.html',
})
export class DataValidationSummaryComponent {
  public timeSaturation = this.store.selectSignal(dataValidationFeature.selectSaturationTest);
  public shannonTest = this.store.selectSignal(dataValidationFeature.selectSamplingTest);
  public stationarity = this.store.selectSignal(dataValidationFeature.selectStationarityTest);

  public navigationToAnchorRequested = output<string>();

  constructor(private store: Store) {}

  public tilesData = computed((): ExtendedTileData[] => {
    const timeSaturationData = {
      name: 'Time saturation',
      validationStatus: this.timeSaturation().data?.status ?? ValidationStepStatus.Unknown,
      anchorId: DATA_VALIDATION_SECTION.subsections['timeSaturation'].anchorId,
    };

    const shannonData = {
      name: 'Shannon sampling',
      validationStatus: this.shannonTest().data?.status ?? ValidationStepStatus.Unknown,
      anchorId: DATA_VALIDATION_SECTION.subsections['shannon'].anchorId,
    };

    const saturationData = {
      name: 'Detection of non-stationarity',
      validationStatus: this.stationarity().data?.status ?? ValidationStepStatus.Unknown,
      anchorId: DATA_VALIDATION_SECTION.subsections['stationarity'].anchorId,
    };

    return [timeSaturationData, shannonData, saturationData];
  });
}
