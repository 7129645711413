import { ComponentRef, Directive, effect, ElementRef, input, Renderer2, ViewContainerRef } from '@angular/core';
import { IconPosition, OverlayWithIconComponent } from '@components/overlay-with-icon/overlay-with-icon.component';

@Directive({
  selector: '[appHoverCopy]',
  standalone: true,
  host: {
    '[style.position]': '"relative"',
    '[style.overflow]': '"clip"',
  },
})
export class HoverCopyDirective {
  appHoverCopy = input<string>('');
  iconPosition = input<IconPosition>(IconPosition.BottomRight);

  private overlayRef: ComponentRef<OverlayWithIconComponent>;

  constructor(element: ElementRef, vcr: ViewContainerRef, renderer: Renderer2) {
    this.overlayRef = vcr.createComponent(OverlayWithIconComponent);
    this.overlayRef.setInput('icon', 'content_copy');
    this.overlayRef.setInput('clickedMessage', 'Copied to clipboard.');
    this.overlayRef.setInput('tooltip', 'Copy');
    // no need to unsubscribe as OverlayWithIconComponent uses angular output
    this.overlayRef.instance.clicked.subscribe(this.onClick);

    const overlayElement = this.overlayRef.location.nativeElement;
    renderer.setStyle(overlayElement, 'position', 'absolute');
    renderer.setStyle(overlayElement, 'z-index', '1');
    renderer.setStyle(overlayElement, 'inset', '0');

    const host: HTMLElement = element.nativeElement;
    host.insertBefore(overlayElement, host.firstChild);

    effect(() => {
      this.overlayRef.setInput('iconPosition', this.iconPosition());
    });
  }

  onClick = () => {
    window.navigator.clipboard.writeText(this.appHoverCopy());
  };
}
