import { Component, computed, input, output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MaterialModule } from '@modules/material.module';

export enum IconPosition {
  TopLeft = 0,
  TopRight = 1,
  BottomLeft = 2,
  BottomRight = 3,
}

@Component({
  selector: 'app-overlay-with-icon',
  imports: [MaterialModule],
  templateUrl: './overlay-with-icon.component.html',
})
export class OverlayWithIconComponent {
  icon = input.required<string>();
  iconPosition = input<IconPosition>(IconPosition.TopRight);
  tooltip = input<string | undefined>(undefined);
  clickedMessage = input<string | undefined>(undefined);
  clicked = output<void>();

  justify = computed(() => {
    switch (this.iconPosition()) {
      case IconPosition.TopLeft:
      case IconPosition.BottomLeft:
        return 'flex-start';
      default:
        return 'flex-end';
    }
  });

  align = computed(() => {
    switch (this.iconPosition()) {
      case IconPosition.TopLeft:
      case IconPosition.TopRight:
        return 'flex-start';
      default:
        return 'flex-end';
    }
  });

  onClick(event: Event) {
    event.stopPropagation();
    this.clicked.emit();
    const clickedMessage = this.clickedMessage();
    if (clickedMessage) {
      this.snackbar.open(clickedMessage, 'Dismiss', { duration: 1000 });
    }
  }

  constructor(private snackbar: MatSnackBar) {}
}
