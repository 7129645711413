import { Component, computed, input, Signal } from '@angular/core';
import { MaterialModule } from '@modules/material.module';
import {
  getValidationStepStatusColorTransparent,
  getValidationStepStatusTextColor,
  ValidationStepStatus,
} from '../../interface/validation-step-status';

interface SignalBanner {
  backgroundColor: string;
  color: string;
  icon: string;
  text: string;
}

@Component({
  selector: 'app-data-validation-status-badge',
  imports: [MaterialModule],
  templateUrl: './data-validation-status-badge.component.html',
})
export class DataValidationStatusBadgeComponent {
  public status = input.required<ValidationStepStatus>();

  public theme: Signal<SignalBanner> = computed(() => {
    const status = this.status();
    const backgroundColor = getValidationStepStatusColorTransparent(status);
    const color = getValidationStepStatusTextColor(status);

    switch (status) {
      case ValidationStepStatus.Success: {
        return {
          backgroundColor,
          color,
          icon: 'check_circle',
          text: 'Okay',
        };
      }
      case ValidationStepStatus.Warning: {
        return {
          backgroundColor,
          color,
          icon: 'warning',
          text: 'Warning',
        };
      }
      case ValidationStepStatus.Failure: {
        return {
          backgroundColor,
          color,
          icon: 'report',
          text: 'Error',
        };
      }
      case ValidationStepStatus.Neutral: {
        return {
          backgroundColor,
          color,
          icon: 'info_outline',
          text: 'Info',
        };
      }
      default: {
        return {
          backgroundColor,
          color,
          icon: 'help_outline',
          text: 'Unknown',
        };
      }
    }
  });
}
