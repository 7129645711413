@let _additionalCurves = additionalCurves();

<div class="mx-2 flex flex-col gap-4 bg-white">
  <div class="flex flex-row items-center gap-2">
    <span class="font-bold">Scale:</span>
    <mat-radio-group aria-label="Y scale" [value]="scaleValue()" (change)="scaleChanged($event.value)">
      <mat-radio-button value="linear">Linear</mat-radio-button>
      <mat-radio-button value="log10">Logarithmic</mat-radio-button>
    </mat-radio-group>
  </div>
  <mat-divider />
  <div class="flex flex-col">
    <span class="font-bold">Main</span>
    <div class="flex flex-wrap gap-x-6">
      @if (curveOptions().first; as firstCurve) {
        <app-content-toggle
          class="col-span-2"
          [label]="firstCurve.name"
          [contentStyle]="firstCurve.style"
          [uncheckDisabled]="curveToggleDisabled()"
          [checked]="firstCurve.state()"
          (checkedChange)="firstCurve.state.set($event)" />
      }

      @if (hasSecondCurve()) {
        @if (_additionalCurves[0]; as secondCurve) {
          <app-content-toggle
            class="col-span-2"
            [label]="secondCurve.name"
            [contentStyle]="secondCurve.style"
            [uncheckDisabled]="curveToggleDisabled()"
            [checked]="secondCurve.state()"
            (checkedChange)="secondCurve.state.set($event)" />
        }
      }

      @if (hasThirdCurve()) {
        @if (_additionalCurves[1]; as thirdCurve) {
          <app-content-toggle
            class="col-span-2"
            [label]="thirdCurve.name"
            [contentStyle]="thirdCurve.style"
            [uncheckDisabled]="curveToggleDisabled()"
            [checked]="thirdCurve.state()"
            (checkedChange)="thirdCurve.state.set($event)" />
        }
      }
    </div>
  </div>

  <div class="flex flex-col">
    <span class="font-bold" matTooltip="PMC: Probability of MisClassifying" [matTooltipShowDelay]="400">
      Top peak PMC <mat-icon class="scale-75">info_outline</mat-icon>
    </span>
    <div class="flex flex-wrap gap-x-6">
      <app-content-toggle
        class="col-span-2"
        label="High - 1e-4"
        [contentStyle]="highPmcStyle"
        [objectsCount]="highPmcCount()"
        [checked]="showHighPMC()"
        (checkedChange)="showHighPMC.set($event)" />
      <app-content-toggle
        class="col-span-2"
        label="Low - 1e-6"
        [contentStyle]="lowPmcStyle"
        [objectsCount]="lowPmcCount()"
        [checked]="showLowPMC()"
        (checkedChange)="showLowPMC.set($event)" />
      <app-content-toggle
        class="col-span-2"
        label="Very low - 1e-8"
        [contentStyle]="veryLowPmcStyle"
        [objectsCount]="veryLowPmcCount()"
        [checked]="showVeryLowPMC()"
        (checkedChange)="showVeryLowPMC.set($event)" />
    </div>
  </div>

  <div class="flex flex-col">
    <span class="font-bold">Peak Bottom - Peak class</span>
    <div class="flex flex-wrap gap-x-6">
      <app-content-toggle
        label="Sine Wave"
        [contentStyle]="swStyle"
        [objectsCount]="swCount()"
        [checked]="showSW()"
        (checkedChange)="showSW.set($event)" />
      <app-content-toggle
        label="Narrow Band"
        [contentStyle]="nbStyle"
        [objectsCount]="nbCount()"
        [checked]="showNB()"
        (checkedChange)="showNB.set($event)" />
      <app-content-toggle
        label="Sine Wave/Noise doubt"
        [contentStyle]="swNoiseDoubtStyle"
        [objectsCount]="swNoiseDoubtCount()"
        [checked]="showSWNoiseDoubt()"
        (checkedChange)="showSWNoiseDoubt.set($event)" />
      <app-content-toggle
        label="Narrow Band/Noise doubt"
        [contentStyle]="nbNoiseDoubtStyle"
        [objectsCount]="nbNoiseDoubtCount()"
        [checked]="showNBNoiseDoubt()"
        (checkedChange)="showNBNoiseDoubt.set($event)" />
      <app-content-toggle
        class="col-span-2"
        label="Noise"
        [contentStyle]="noisePeaksStyle"
        [objectsCount]="noisePeaksCount()"
        [checked]="showNoise()"
        (checkedChange)="showNoise.set($event)" />
    </div>
  </div>
</div>
