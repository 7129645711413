import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, output, signal } from '@angular/core';
import { MaterialModule } from '@modules/material.module';

@Component({
  selector: 'app-drag-and-drop',
  imports: [CommonModule, MaterialModule],
  templateUrl: './drag-and-drop.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DragAndDropComponent {
  canUpload = input<boolean>(true);

  dragStopped = output();
  filesDropped = output<File[]>();

  dragging = signal<boolean>(false);
  opacity = computed(() => (this.dragging() ? 1.0 : 0.5));

  onDragEnter() {
    this.dragging.set(true);
  }

  onDragLeave(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();
    this.dragging.set(false);
    this.dragStopped.emit();
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();

    if (this.canUpload()) {
      const files: FileList | undefined = event.dataTransfer?.files;
      if (files && files.length > 0) {
        this.filesDropped.emit(Array.from(files));
      }
    }
    this.dragging.set(false);
    this.dragStopped.emit();
  }
}
