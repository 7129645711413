import { Component, input, signal } from '@angular/core';
import { AuthActions } from '@features/auth/shared/store/auth.actions';
import { authFeature } from '@features/auth/shared/store/auth.feature';
import { MaterialModule } from '@modules/material.module';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-account-menu',
  imports: [MaterialModule],
  templateUrl: './account-menu.component.html',
})
export class AccountMenuComponent {
  expanded = input.required<boolean>();

  isLoggedIn = this.store.selectSignal(authFeature.selectIsLoggedIn);
  user = this.store.selectSignal(authFeature.selectUser);

  menuOpened = signal<boolean>(false);

  constructor(private store: Store) {}

  logout() {
    this.store.dispatch(AuthActions.logoutRequested({ redirectUri: window.location.origin }));
  }
}
