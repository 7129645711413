import { Component, computed, input } from '@angular/core';
import { IconPosition } from '@components/overlay-with-icon/overlay-with-icon.component';
import { MaterialModule } from '@modules/material.module';
import { HoverCopyDirective } from '@shared/directives/hover-copy.directive';

export interface TileData {
  title: string;
  content: string;
  contentToCopy?: string;
}

@Component({
  selector: 'app-metadata-tile',
  imports: [MaterialModule, HoverCopyDirective],
  templateUrl: './metadata-tile.component.html',
})
export class MetadataTileComponent {
  public data = input.required<TileData>();

  public tileTitle = computed(() => this.data().title);
  public tileContent = computed(() => this.data().content);
  public tileContentToCopy = computed(() => {
    const data = this.data();
    return data.contentToCopy ?? data.content;
  });

  overlayIconPosition = IconPosition.TopRight;
}
