import { REPORT_STRUCTURE, ReportSection } from '../../interface/report-structure';

export interface AnchorContent {
  htmlElement: HTMLElement;
  sectionExpansionCallback: () => Promise<void>;
}

export type ReportAnchors = Map<string, AnchorContent | undefined>;

export const defaultReportAnchors = (): ReportAnchors => {
  const anchors: [string, AnchorContent | undefined][] = [];

  for (const sectionName in REPORT_STRUCTURE.sections) {
    const section = REPORT_STRUCTURE.sections[sectionName];

    anchors.push([section.anchorId, undefined]);

    for (const subSectionName in section.subsections) {
      const subSection = section.subsections[subSectionName];

      anchors.push([subSection.anchorId, undefined]);
    }
  }

  return new Map([...anchors]);
};

export const sectionMatchingOrContainingId = (anchorId: string): ReportSection | undefined => {
  for (const sectionName in REPORT_STRUCTURE.sections) {
    const section = REPORT_STRUCTURE.sections[sectionName];

    if (section.anchorId === anchorId) {
      return section;
    }

    for (const subSectionName in section.subsections) {
      const subSection = section.subsections[subSectionName];

      if (subSection.anchorId === anchorId) {
        return section;
      }
    }
  }

  return undefined;
};
