import { ComponentRef, Directive, effect, ElementRef, input, output, Renderer2, ViewContainerRef } from '@angular/core';
import { IconPosition, OverlayWithIconComponent } from '@components/overlay-with-icon/overlay-with-icon.component';

@Directive({
  selector: '[appHoverGoTo]',
  standalone: true,
  host: {
    '[style.position]': '"relative"',
    '[style.overflow]': '"clip"',
  },
})
export class HoverGoToDirective {
  appHoverGoTo = input<string | undefined>(undefined);
  iconPosition = input<IconPosition>(IconPosition.BottomRight);
  clicked = output<void>();
  private overlayRef: ComponentRef<OverlayWithIconComponent>;

  constructor(element: ElementRef, vcr: ViewContainerRef, renderer: Renderer2) {
    this.overlayRef = vcr.createComponent(OverlayWithIconComponent);
    this.overlayRef.setInput('icon', 'open_in_new');

    // no need to unsubscribe as OverlayWithIconComponent uses angular output
    this.overlayRef.instance.clicked.subscribe(() => this.clicked.emit());

    const overlayElement = this.overlayRef.location.nativeElement;
    renderer.setStyle(overlayElement, 'position', 'absolute');
    renderer.setStyle(overlayElement, 'z-index', '1');
    renderer.setStyle(overlayElement, 'inset', '0');

    const host: HTMLElement = element.nativeElement;
    host.insertBefore(overlayElement, host.firstChild);

    effect(() => {
      this.overlayRef.setInput('iconPosition', this.iconPosition());
    });

    effect(() => {
      this.overlayRef.setInput('tooltip', this.appHoverGoTo());
    });
  }
}
