import { Component, computed, input, output, signal, viewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { GlGraphComponent, ImageExportOptions } from '@astrion-webtools/graph';
import { MaterialModule } from '@modules/material.module';
import { download, downloadImageBlob } from '@tools/utilities/download';
import { GraphWrapperBaseLegendComponent } from './graph-wrapper-base-legend/graph-wrapper-base-legend.component';
import { CurveLegend } from './graph-wrapper-base.interface';
import { GraphWrapperDrawerComponent } from './graph-wrapper-drawer/graph-wrapper-drawer.component';
import { GraphExportService } from '@services/graph-export.service';

@Component({
  selector: 'app-graph-wrapper-base',
  imports: [MaterialModule, GraphWrapperBaseLegendComponent, GraphWrapperDrawerComponent],
  templateUrl: './graph-wrapper-base.component.html',
})
export class GraphWrapperBaseComponent {
  xTitle = input.required<string>();
  graphHeight = input<string | undefined>(undefined);
  hasData = input.required<boolean>();
  graph = input.required<GlGraphComponent | undefined>();
  name = input.required<string>();
  showDownload = input(true);
  showLegend = input(false);
  showDelete = input(false);
  showSettings = input(false);
  showInfos = input(false);
  showExportImage = input(true);
  collapsable = input(true);

  downloadDisabled = input(false);
  legend = input<CurveLegend[] | undefined>(undefined);

  // Export options that override default ones but can be overriden by user
  imageExportOptions = input<Partial<ImageExportOptions> | undefined>(undefined);

  delete = output();

  panel = viewChild.required<MatExpansionPanel>('panel');
  menuOpen = signal<boolean>(false);

  drawerShowSettings = signal<boolean>(true);
  drawerShowInfos = computed(() => !this.drawerShowSettings());

  constructor(private exportService: GraphExportService) {}

  onSettings(event: Event) {
    event.stopPropagation();
    if (this.drawerShowSettings() && this.menuOpen()) {
      this.menuOpen.set(false);
      return;
    }
    this.drawerShowSettings.set(true);
    this.menuOpen.set(true);
  }

  onInfos(event: Event) {
    event.stopPropagation();
    if (this.drawerShowInfos() && this.menuOpen()) {
      this.menuOpen.set(false);
      return;
    }
    this.drawerShowSettings.set(false);
    this.menuOpen.set(true);
  }

  onDelete(event: Event) {
    this.delete.emit();
    event.stopPropagation();
  }

  onTogglePanel(event: Event) {
    this.expanded.update(e => !e);
    event.stopPropagation();
  }

  onExportImage = async (event: Event) => {
    event.stopPropagation();
    const graph = this.graph();
    if (!graph) {
      return;
    }
    const image = await this.exportService.openImageExportDialog(graph, this.imageExportOptions());
    if (image) {
      downloadImageBlob('image.png', image);
    }
  };

  onDownload = async (event: Event) => {
    event.stopPropagation();
    const graph = this.graph();
    if (!graph) {
      return;
    }
    const options = await this.exportService.askForCsvExportOptions(this.xTitle());
    if (options) {
      const csvText = await graph.exportCsv(options);
      download('export.csv', csvText);
    }
  };

  expanded = signal(true);
}
