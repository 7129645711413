import { AStrionSignal, AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const SignalReportActions = createActionGroup({
  source: 'SignalReport',
  events: {
    signalBaseDataFetchRequested: props<{ signalId: AStrionSignalId }>(),
    signalBaseDataFetched: props<{ signalId: AStrionSignalId; signal: AStrionSignal }>(),
    signalBaseDataNotFound: props<{ signalId: AStrionSignalId }>(),
    signalBaseDataFetchFailed: props<{ signalId: AStrionSignalId }>(),

    sectionRegistration: props<{
      anchorId: string;
      htmlElement: HTMLElement;
      sectionExpansionCallback: () => Promise<void>;
    }>(),
    subSectionRegistration: props<{ anchorId: string; htmlElement: HTMLElement }>(),

    navigatedOut: emptyProps(),
  },
});
