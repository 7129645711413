import { animate, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, computed, input } from '@angular/core';

@Component({
  selector: 'app-graph-wrapper-drawer',
  imports: [CommonModule],
  templateUrl: './graph-wrapper-drawer.component.html',
  animations: [
    trigger('openCloseContent', [
      state(
        'open',
        style({
          width: '70%',
        })
      ),
      state(
        'close',
        style({
          width: '100%',
        })
      ),
      transition('open <=> close', [animate('0.2s')]),
    ]),
    trigger('openCloseMenu', [
      state(
        'open',
        style({
          left: '70%',
          opacity: '1',
        })
      ),
      state(
        'close',
        style({
          left: '110%',
          opacity: '0',
        })
      ),
      transition('open <=> close', [animate('0.2s')]),
    ]),
  ],
})
export class GraphWrapperDrawerComponent {
  height = input<string | undefined>(undefined);
  open = input.required<boolean>();
  openClose = computed(() => (this.open() ? 'open' : 'close'));
}
