export interface ReportSubSection {
  anchorId: string;
  name: string;
}

export interface SubSectionList {
  [subSectionName: string]: ReportSubSection;
}

export interface ReportSection {
  anchorId: string;
  name: string;
  icon: string;
  subsections: SubSectionList;
}

export interface ReportStructure {
  sections: ReportSection[];
}

export const OVERVIEW_SECTION: ReportSection = {
  anchorId: 'overview',
  name: 'Signal overview',
  icon: 'landscape',
  subsections: {},
};

export const SPECTRAL_ANALYSIS_SECTION: ReportSection = {
  anchorId: 'spectral-analysis',
  name: 'Spectral analysis',
  icon: 'troubleshoot',
  subsections: {
    spectrums: {
      anchorId: 'spectrums',
      name: 'Spectrums',
    },
    spectralAnalysisResults: {
      anchorId: 'spectral-analysis-results',
      name: '', // Name is dynamic, depends on conditions, so generated in component.
    },
  },
};

export const DATA_VALIDATION_SECTION: ReportSection = {
  anchorId: 'data-validation',
  name: 'Data validation',
  icon: 'checklist',
  subsections: {
    timeSaturation: {
      anchorId: 'time-saturation',
      name: 'Time Saturation',
    },
    shannon: {
      anchorId: 'shannon-test',
      name: 'Shannon test',
    },
    stationarity: {
      anchorId: 'stationarity',
      name: 'Stationarity',
    },
  },
};

export const REPORT_STRUCTURE: ReportStructure = {
  sections: [OVERVIEW_SECTION, SPECTRAL_ANALYSIS_SECTION, DATA_VALIDATION_SECTION],
};
