import { booleanAttribute, Component, input, output } from '@angular/core';
import { MaterialModule } from '@modules/material.module';

export enum ButtonsAction {
  SaveDefault,
  SaveForGraph,
  ResetDefault,
  ResetGraph,
}

@Component({
  selector: 'app-graph-image-export-dialog-buttons',
  standalone: true,
  imports: [MaterialModule],
  templateUrl: './graph-image-export-dialog-buttons.component.html',
})
export class GraphImageExportDialogButtonsComponent {
  defaultChoiceDisabled = input(false, { transform: booleanAttribute });
  isGlobalDefault = input(false);
  isGraphDefault = input(false);
  onAction = output<ButtonsAction>();

  onSaveDefault() {
    this.onAction.emit(ButtonsAction.SaveDefault);
  }

  onSaveForGraph() {
    this.onAction.emit(ButtonsAction.SaveForGraph);
  }

  onResetDefault() {
    this.onAction.emit(ButtonsAction.ResetDefault);
  }

  onResetGraph() {
    this.onAction.emit(ButtonsAction.ResetGraph);
  }
}
