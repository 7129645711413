import {
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  input,
  viewChild,
  ViewEncapsulation,
} from '@angular/core';
import { DataValidationStatusBadgeComponent } from '@features/data-validation/shared/components/data-validation-status-badge/data-validation-status-badge.component';
import { ValidationStepStatus } from '@features/data-validation/shared/interface/validation-step-status';
import { MaterialModule } from '@modules/material.module';

@Component({
  selector: 'app-status-banner',
  imports: [MaterialModule, DataValidationStatusBadgeComponent],
  templateUrl: './status-banner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styles: '.banner .mat-expansion-panel-body { padding: 0 !important; }',
})
export class StatusBannerComponent {
  status = input.required<ValidationStepStatus>();
  message = input.required<string>();

  public panelExpanded: boolean = false;

  public additionalContent = viewChild('additionalContent', { read: ElementRef });

  public hasAdditionalContent = computed(() => {
    const additionalContent = this.additionalContent();

    return (
      additionalContent !== undefined &&
      (additionalContent.nativeElement.children.length !== 0 ||
        (additionalContent.nativeElement.textContent !== null &&
          additionalContent.nativeElement.textContent.length !== 0))
    );
  });

  public togglePanel(): void {
    if (!this.hasAdditionalContent()) {
      return;
    }

    this.panelExpanded = !this.panelExpanded;
  }
}
