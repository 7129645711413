import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MaterialModule } from '@modules/material.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateFilterModel } from '@shared/interfaces/filter-model';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import moment from 'moment';

@Component({
  selector: 'app-date-filter-dialog',
  templateUrl: './date-filter-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MaterialModule, FormsModule, MatDatepickerModule, MatFormFieldModule, ReactiveFormsModule],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'fr-FR' }, provideMomentDateAdapter()],
})
export class DateFilterDialogComponent {
  minimum: moment.Moment | undefined;
  maximum: moment.Moment | undefined;

  readonly range = new FormGroup({
    start: new FormControl<moment.Moment | null>((this.data.min && moment(this.data.min)) || null),
    end: new FormControl<moment.Moment | null>((this.data.max && moment(this.data.max)) || null),
  });

  constructor(
    private dialogRef: MatDialogRef<DateFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DateFilterDialogData,
    private dateAdapter: DateAdapter<unknown>
  ) {
    this.minimum = moment(this.data.min);
    this.maximum = moment(this.data.max);
    this.dateAdapter.setLocale('fr-FR');
  }

  closeWithFilter() {
    this.dialogRef.close({
      min: this.range.controls.start.value ?? this.minimum,
      max: this.range.controls.end.value ?? this.maximum,
    });
  }
}

export interface DateFilterDialogData {
  min: Date | undefined;
  max: Date | undefined;
  filter?: DateFilterModel;
}
