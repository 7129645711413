import { ChangeDetectionStrategy, Component } from '@angular/core';
import environment from '@environments/environment.json';
import packageJson from '@root/../package.json';

@Component({
  selector: 'app-application-data',
  imports: [],
  templateUrl: './application-data.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicationDataComponent {
  environment = environment.ENV;
  baseUrl = environment.BASE_URL;
  version: string = packageJson.version;
}
