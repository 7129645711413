@let _hasData = hasData();
@let _expanded = expanded();

<mat-accordion>
  <mat-expansion-panel #panel class="w-full bg-white" [expanded]="_expanded" hideToggle disabled>
    <mat-expansion-panel-header class="mx-4 flex cursor-default flex-row items-center gap-2 py-2 text-sm">
      @let _name = name();

      @if (_name.length > 0) {
        <mat-panel-title class="select-none !text-black">{{ _name }}</mat-panel-title>
      }
      <span class="flex flex-grow flex-row items-center">
        <ng-content select="[headers]" />
      </span>
      <span class="flex flex-none flex-row items-center">
        @if (showInfos()) {
          <button mat-icon-button class="graph-action-button" [disabled]="!_hasData" (click)="onInfos($event)">
            <mat-icon>info</mat-icon>
          </button>
        }
        @if (showSettings()) {
          <button
            mat-icon-button
            class="graph-action-button"
            (click)="onSettings($event)"
            [disabled]="!_hasData || !_expanded">
            <mat-icon>settings</mat-icon>
          </button>
        }
        @if (showExportImage()) {
          <button
            mat-icon-button
            class="graph-action-button"
            (click)="onExportImage($event)"
            [disabled]="!_hasData || !_expanded">
            <mat-icon>photo_camera</mat-icon>
          </button>
        }
        @if (showDownload()) {
          <button
            mat-icon-button
            class="graph-action-button"
            (click)="onDownload($event)"
            [disabled]="downloadDisabled() || !_hasData || !graph()">
            <mat-icon>download</mat-icon>
          </button>
        }
        @if (showDelete()) {
          <button mat-icon-button class="graph-action-button" (click)="onDelete($event)" [disabled]="!_hasData">
            <mat-icon>delete</mat-icon>
          </button>
        }

        @if (collapsable()) {
          <button mat-icon-button class="graph-action-button" (click)="onTogglePanel($event)">
            <mat-icon>{{ _expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
          </button>
        }
      </span>
    </mat-expansion-panel-header>

    <div class="flex flex-col">
      <mat-divider class="border-outline-variant mb-3" />

      @if (legend(); as legend) {
        <app-graph-wrapper-base-legend [legend]="legend" />
      }

      <app-graph-wrapper-drawer [open]="menuOpen()" [height]="graphHeight()">
        <div content class="relative h-full w-full">
          <ng-content select="[graph]" />
          @if (!_hasData) {
            <div class="absolute inset-0 flex flex-row content-center items-center justify-center gap-2">
              <mat-icon class="align-middle">block</mat-icon><span>No content</span>
            </div>
          }
        </div>
        <div class="relative h-full w-full bg-white" menu>
          <div class="h-full w-full overflow-y-auto p-0">
            @if (drawerShowSettings()) {
              <ng-content select="[settings]" />
            }
            @if (drawerShowInfos()) {
              <ng-content select="[infos]" />
            }
          </div>
        </div>
      </app-graph-wrapper-drawer>
    </div>
  </mat-expansion-panel>
</mat-accordion>
