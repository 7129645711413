import { ChangeDetectionStrategy, Component, computed } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { EntityNotFoundComponent } from '@components/entity-not-found/entity-not-found.component';
import { ProjectRouter } from '@features/auth/shared/services/projectRouter';
import { authFeature } from '@features/auth/shared/store/auth.feature';
import { Equipment } from '@features/equipments/shared/interfaces/equipment.interface';
import { EquipmentsActions } from '@features/equipments/shared/store/equipments.actions';
import { equipmentsFeature } from '@features/equipments/shared/store/equipments.feature';
import { sensorSignalsFeature } from '@features/sensor-signals/shared/store/sensor-signals.feature';
import { SensorOverviewComponent } from '@features/sensors/components/sensor-overview/sensor-overview.component';
import { SensorComponent } from '@features/sensors/components/sensor/sensor.component';
import { Sensor } from '@features/sensors/shared/interfaces/sensor.interface';
import { SensorDialogService } from '@features/sensors/shared/services/sensor-dialog.service';
import { sensorsFeature } from '@features/sensors/shared/store/sensors.feature';
import { Store } from '@ngrx/store';
import { PathBarComponent } from '../../../../shared/components/path-bar/path-bar.component';

@Component({
  selector: 'app-sensor-view',
  templateUrl: './sensor-view.component.html',
  imports: [SensorComponent, PathBarComponent, EntityNotFoundComponent, SensorOverviewComponent, MatProgressBarModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SensorDialogService],
})
export class SensorViewComponent {
  id = this.store.selectSignal(sensorsFeature.selectCurrentSensorId);
  public canEdit = this.store.selectSignal(authFeature.selectCanEdit);

  equipment = computed(() => {
    const sensor = this.sensor();
    if (!sensor) return undefined;
    return this.store.selectSignal(equipmentsFeature.selectEquipment(sensor.equipmentId))();
  });
  sensor = this.store.selectSignal(sensorsFeature.selectCurrentSensor);
  samplingCondition = this.store.selectSignal(sensorSignalsFeature.selectSamplingCondition);
  isLoading = computed(() => this.sensorSignalsLoading() || this.equipmentsLoading());

  private equipmentsLoading = this.store.selectSignal(equipmentsFeature.selectIsLoading);
  private sensorSignalsLoading = this.store.selectSignal(sensorSignalsFeature.selectIsLoading);

  constructor(
    private store: Store,
    private router: ProjectRouter,
    private sensorDialogService: SensorDialogService
  ) {
    this.store.dispatch(EquipmentsActions.refreshIfNotInit());
  }

  edit(sensor: Sensor) {
    this.sensorDialogService.openUpdate(sensor);
  }

  navigateToEquipment(equipment: Equipment) {
    this.router.navigateByUrl(`/equipment/${equipment.id}`);
  }

  navigateToRoot() {
    this.router.navigateByUrl(`/operation`);
  }
}
