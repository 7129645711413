import { Component, computed, input } from '@angular/core';

@Component({
  selector: 'app-graph-image-export-dialog-preview',
  imports: [],
  templateUrl: './graph-image-export-dialog-preview.component.html',
})
export class GraphImageExportDialogPreviewComponent {
  image = input.required<Blob | undefined>();
  imageUrl = computed(() => {
    const image = this.image();
    return image ? URL.createObjectURL(image) : undefined;
  });
}
