import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-entity-not-found',
  templateUrl: './entity-not-found.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatIconModule, RouterLink],
})
export class EntityNotFoundComponent {
  error = input<boolean>(false);
  notFound = input<boolean>(false);
  entityName = input.required<string>();
}
