import { ChangeDetectionStrategy, Component, input, output, signal } from '@angular/core';
import { BorderGradientComponent } from '@components/border-gradient/border-gradient.component';

@Component({
  selector: 'app-scrollable-bordered-container',
  imports: [BorderGradientComponent],
  templateUrl: './scrollable-bordered-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScrollableBorderedContainerComponent {
  public fadeBottom = input(false);
  public fadeTop = input(false);
  contentScrolled = output();

  isTop = signal<boolean>(true);
  isBottom = signal<boolean>(false);

  onContentScrolled(event: Event) {
    const element = event.target as HTMLElement;
    this.isTop.set(element.scrollTop === 0);
    this.isBottom.set(element.scrollTop + element.clientHeight === element.scrollHeight);
    this.contentScrolled.emit();
  }
}
