import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, Signal } from '@angular/core';
import { MaterialModule } from '@modules/material.module';
import { ItemDescription } from '@shared/interfaces/item-description';
import { parseComputingStatus, ComputationStatus } from '@shared/interfaces/processing-status';

@Component({
  selector: 'app-signal-icon',
  imports: [CommonModule, MaterialModule],
  templateUrl: './signal-icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignalIconComponent {
  signalStatus = input.required<ComputationStatus | undefined>();
  computationMessage = input.required<string | undefined>();

  itemDescription: Signal<ItemDescription> = computed(() => {
    const signalStatus = this.signalStatus();
    const computationMessage = this.computationMessage();
    return parseComputingStatus(signalStatus, computationMessage);
  });
}
