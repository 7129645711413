import { Component, input, output } from '@angular/core';
import { MaterialModule } from '@modules/material.module';
import { FilterDialogService } from '@services/filter-dialog.service';
import { OptionModel, OptionFilterModel } from '@shared/interfaces/filter-model';

@Component({
  selector: 'app-option-filter',
  providers: [FilterDialogService],
  imports: [MaterialModule],
  templateUrl: './filter.component.html',
  styles: ``,
})
export class OptionFilterComponent {
  values = input.required<OptionModel[]>();
  inputFilter = input.required<OptionFilterModel | undefined>();
  outputFilter = output<OptionFilterModel | undefined>();

  constructor(private filterDialogService: FilterDialogService) {}

  async openFilterDialog(event: MouseEvent) {
    event.stopPropagation();

    const filter = await this.filterDialogService.openOptionFilter(
      { options: this.values(), filter: this.inputFilter() },
      event.clientX,
      event.clientY
    );

    this.outputFilter.emit(filter ? (filter.valuedOptions.every(o => o.value) ? undefined : filter) : undefined);
  }
}
