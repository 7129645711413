import { Component, input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { LoadingLogoComponent } from '@components/loading-logo/loading-logo.component';

@Component({
  selector: 'app-home-button',
  imports: [RouterLink, LoadingLogoComponent],
  templateUrl: './home-button.component.html',
})
export class HomeButtonComponent {
  expanded = input.required<boolean>();
}
