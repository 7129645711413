<div class="flex h-full w-full flex-col gap-1">
  <div class="relative flex w-full items-center">
    <span class="flex-1"></span>
    <span class="flex-grow text-center text-lg font-medium shadow-slate-300 text-shadow-sm">{{ graphTitle() }}</span>
    <span class="flex flex-1 justify-center">
      <button mat-icon-button (click)="onExport()"><mat-icon>photo_camera</mat-icon></button>
    </span>
  </div>
  <lib-gl-graph
    #graph
    class="flex-grow"
    [id]="id()"
    [data]="data()"
    yTitle="Frequency (Hz)"
    xTitle="Time (s)"
    group="stationarity"
    [xAxisDataIsTimestamps]="false"
    [xMeanTicks]="5" />
</div>
