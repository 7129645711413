import { Component, computed, effect, Input, input, output, signal, viewChild } from '@angular/core';
import { ColorGenerationService, CurveId, GraphInput } from '@astrion-webtools/graph';
import { MaterialModule } from '@modules/material.module';
import { mapIntersectKeys } from '@tools/utilities/map-utils';

import { GroupName, GroupState, GroupStateMap } from './trajectory-graph.interface';
import { TrajectoryGraphSettingsComponent } from './trajectory-graph-settings/trajectory-graph-settings.component';
import { dataMerge } from './utils/data-merge';
import { GraphWrapperComponent } from '@components/graph-wrapper/graph-wrapper.component';

// We completely ignore areas here
@Component({
  selector: 'app-trajectory-graph',
  imports: [MaterialModule, TrajectoryGraphSettingsComponent, GraphWrapperComponent],
  templateUrl: './trajectory-graph.component.html',
})
export class TrajectoryGraphComponent {
  name = input.required<string>();
  xTitle = input.required<string>();
  yTitle = input.required<string>();
  graphHeight = input('70vh');

  @Input() set data(next: GraphInput | undefined) {
    this._data.update(prev => dataMerge(prev, next, this._defaultColors));
  }
  private _data = signal<GraphInput>({});

  removeCurve = output<string>();
  delete = output();

  settings = viewChild.required<TrajectoryGraphSettingsComponent>('settings');

  focusedCurve = signal<string | undefined>(undefined);
  groupStates = signal<GroupStateMap>(new Map());

  groupIdx = 1;

  private _defaultColors: string[];

  curvesGrouped(CurveIds: CurveId[]) {
    const curvesSet = new Set<CurveId>(CurveIds);
    const groupName = `${this.groupIdx++}`.padStart(4, '0');
    this._data.update(data => ({
      ...data,
      curves: data.curves?.map(curve => ({
        ...curve,
        axisGroup: curvesSet.has(curve.id) ? groupName : curve.axisGroup,
      })),
    }));
  }

  curvesUngrouped(CurveIds: CurveId[]) {
    const curvesSet = new Set<CurveId>(CurveIds);
    this._data.update(data => ({
      ...data,
      curves: data.curves?.map(curve => ({
        ...curve,
        axisGroup: curvesSet.has(curve.id) ? undefined : curve.axisGroup,
      })),
    }));
  }

  groups = computed<GroupName[]>(() => {
    const data = this._data();
    if (!data?.curves) {
      return [];
    }
    return Array.from(
      new Set(data.curves.map(curve => curve.axisGroup).filter(group => group !== undefined) as GroupName[])
    );
  });

  visibleGroups = computed<Set<GroupName | undefined>>(() => {
    const groups = this.groups();
    const groupStates = this.groupStates();
    const hasSingles = Array.from(groupStates.values()).some(groupToggle => groupToggle === GroupState.Single);
    return new Set<GroupName | undefined>(
      [undefined, ...groups].filter(groupName => {
        const toggle: GroupState | undefined = groupStates.get(groupName);
        return hasSingles ? toggle === GroupState.Single : toggle !== GroupState.Hide;
      })
    );
  });

  visibleCurves = computed<Set<CurveId>>(() => {
    const data = this._data();
    const visibleGroups = this.visibleGroups();

    return new Set(data?.curves?.filter(curve => visibleGroups.has(curve.axisGroup))?.map(curve => curve.id) ?? []);
  });

  completedData = computed<GraphInput>(() => {
    const data = this._data();
    const focusedCurve = this.focusedCurve();
    const visibleCurves = this.visibleCurves();

    return {
      ...data,
      curves: data.curves?.map(curve => ({
        ...curve,
        drawPoints: focusedCurve === curve.id,
        display: visibleCurves.has(curve.id),
      })),
    } as GraphInput;
  });

  showGroupAxis = computed(() => {
    const completedData = this.completedData();

    if (!completedData.curves || completedData.curves.length <= 0) {
      return undefined;
    }

    const displayedCurves = completedData.curves.filter(curve => curve.display ?? true);
    if (displayedCurves.length <= 0) {
      return undefined;
    }

    return displayedCurves
      .filter(curve => curve.display ?? true)
      .reduce(
        (prev, cur) => {
          return prev !== undefined && prev === (cur.axisGroup ?? null) ? prev : undefined;
        },
        (displayedCurves[0].axisGroup ?? null) as string | undefined | null
      );
  });

  hasData = computed(() => {
    const completedData = this.completedData();
    return completedData && completedData.curves?.length;
  });

  constructor(colorGeneration: ColorGenerationService) {
    this._defaultColors = colorGeneration.generateColors(64);
    effect(() => {
      const groups = new Set(this.groups());
      // remove obsolete groups
      this.groupStates.update(groupStates => mapIntersectKeys(groupStates, groups));
    });
  }

  onColorSelected({ name, color }: { name: CurveId; color: string }) {
    this._data.update(data => ({
      ...data,
      curves: data.curves?.map(curve => (curve.id === name ? { ...curve, color } : curve)),
    }));
  }
}
