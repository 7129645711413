import { APP_INITIALIZER, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RealtimeClientService } from '@features/realtime/shared/services/realtime-client.service';
import { EffectsModule } from '@ngrx/effects';
import { Store, StoreModule } from '@ngrx/store';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';

import { AUTH_ROUTES } from './auth.routes';
import { initializer } from './keycloak-initializer';
import { AuthService } from './shared/services/auth.service';
import { ProjectRouter } from './shared/services/projectRouter';
import { AuthEffects } from './shared/store/auth.effects';
import { authFeature } from './shared/store/auth.feature';

@NgModule({
  declarations: [],
  imports: [
    KeycloakAngularModule,
    RouterModule.forChild(AUTH_ROUTES),
    StoreModule.forFeature(authFeature),
    EffectsModule.forFeature([AuthEffects]),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      multi: true,
      deps: [KeycloakService, Store, RealtimeClientService],
    },
    AuthService,
    ProjectRouter,
  ],
})
export class AuthModule {}
