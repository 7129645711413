import { BaseSelectors } from '@app-types/extra-selectors-factory';
import {
  AStrionSignal,
  AStrionSignalId,
  AStrionSignalWithStatus,
  getDuration,
} from '@features/signals/shared/interface/astrion-signal.interface';
import { createSelector } from '@ngrx/store';
import { LoadingState } from '@shared/interfaces/loading-state';

import { SamplingCondition } from '../interfaces/sampling-condition.interface';
import { name, SensorSignalsState } from './sensor-signals.state';

export const extraSelectors = ({
  selectSignals,
  selectStatuses,
  selectLoadingState,
}: BaseSelectors<typeof name, SensorSignalsState>) => ({
  selectIsLoading: createSelector(selectLoadingState, loadingState => loadingState === LoadingState.Loading),

  selectContainsSignal: (signalId: AStrionSignalId) =>
    createSelector(selectSignals, signals => signals.some(s => s.id === signalId)),

  selectDuplicateDateSignalId: createSelector(selectSignals, signals => {
    return signals
      .filter(signal => !!signal.date)
      .map(signal => ({ id: signal.id, dateTime: signal.date?.getTime() ?? 0, createTime: signal.createdAt.getTime() }))
      .sort((a, b) => (a.dateTime === b.dateTime ? a.createTime - b.createTime : a.dateTime - b.dateTime))
      .filter((signal, index, sorted) => index > 0 && sorted[index - 1].dateTime === signal.dateTime)
      .map(s => s.id);
  }),

  selectSignalsWithStatus: createSelector(
    selectSignals,
    selectStatuses,
    (signals, statuses): AStrionSignalWithStatus[] => {
      return signals.map(signal => ({
        ...signal,
        status: statuses[signal.id],
      }));
    }
  ),

  selectNonStationarityValues: createSelector(selectSignals, selectStatuses, (signals, statuses) => {
    return signals
      .filter(signal => !!signal.date)
      .sort((a, b) => a.date!.getTime() - b.date!.getTime())
      .map(signal => [
        signal.date!.getTime(),
        statuses[signal.id]?.validationFlags?.nonStationnarity?.percentage ?? NaN,
      ])
      .flat();
  }),

  selectSamplingCondition: createSelector(selectSignals, (signals: AStrionSignal[]): SamplingCondition | null => {
    if (signals.length === 0) {
      return null;
    }
    return {
      frequency: signals[0].samplingFrequency,
      sampleCount: signals[0].samplesCount,
      duration: getDuration(signals[0]),
    };
  }),
});
