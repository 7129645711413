import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSliderModule } from '@angular/material/slider';
import { MaterialModule } from '@modules/material.module';
import { OptionFilterModel, OptionModel } from '@shared/interfaces/filter-model';

@Component({
  selector: 'app-option-filter-dialog',
  templateUrl: './option-filter-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MaterialModule, FormsModule, MatSliderModule],
})
export class OptionFilterDialogComponent {
  filter: OptionFilterModel;

  constructor(
    private dialogRef: MatDialogRef<OptionFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: OptionFilterDialogData
  ) {
    this.filter = data.filter ? data.filter : { valuedOptions: data.options.map(o => ({ ...o, value: true })) };
  }

  closeWithFilter() {
    this.dialogRef.close({ ...this.filter });
  }
}

export interface OptionFilterDialogData {
  options: OptionModel[];
  filter?: OptionFilterModel;
}
