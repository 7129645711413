import { ChangeDetectionStrategy, Component, computed, effect, Signal, signal, viewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { getProgressState, Progress, ProgressState } from '@app-types/progress.interface';
import { UploadResultListComponent } from '@features/signals/components/upload-result-list/upload-result-list.component';
import {
  AStrionSignalUploadResult,
  AStrionSignalUploadStatus,
} from '@features/signals/shared/interface/astrion-signal-upload-result.interface';
import { SignalsActions } from '@features/signals/shared/store/signals.actions';
import { signalsFeature } from '@features/signals/shared/store/signals.feature';
import { MaterialModule } from '@modules/material.module';
import { Store } from '@ngrx/store';

import { UploadProgressScreenComponent } from '../upload-progress-screen/upload-progress-screen.component';

@Component({
  selector: 'app-upload-status-dialog',
  templateUrl: './upload-status-dialog.component.html',
  imports: [MaterialModule, UploadProgressScreenComponent, UploadResultListComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadStatusDialogComponent {
  public closeButton = viewChild('closeButton', { read: HTMLButtonElement });

  uploadProgress: Signal<Progress | null> = this.store.selectSignal(signalsFeature.selectUploadProgress);
  uploads: Signal<AStrionSignalUploadResult[]> = this.store.selectSignal(signalsFeature.selectLastUpload);

  uploadState: Signal<ProgressState> = computed(() => getProgressState(this.uploadProgress()));
  showSuccess = signal(false);

  isFinished = computed(() => this.uploadState().finished);
  filteredUploads = computed(() => {
    if (this.showSuccess()) {
      return this.uploads();
    } else {
      return this.uploads().filter(upload => upload.status !== AStrionSignalUploadStatus.Success);
    }
  });

  title: Signal<string> = computed(() => {
    const progressState = this.uploadState();
    return progressState.uploading
      ? 'Uploading signals'
      : progressState.processing
        ? 'Processing response'
        : 'Finished uploading (with errors)';
  });

  constructor(
    private store: Store,
    private dialogRef: MatDialogRef<UploadStatusDialogComponent>,
    snackBar: MatSnackBar
  ) {
    effect(() => {
      this.dialogRef.disableClose = this.uploadProgress !== null;
    });
    effect(() => {
      if (this.uploadProgress() === null) {
        const uploads = this.uploads();
        if (uploads.length > 0 && uploads.every(upload => upload.status === AStrionSignalUploadStatus.Success)) {
          snackBar.open('Successfully uploaded signals.', 'Dismiss', {
            duration: 3000,
          });
          this.dialogRef.close();
        }
      }
    });
    effect(() => {
      if (this.isFinished()) {
        const closeButton = this.closeButton();

        if (closeButton === undefined) {
          return;
        }

        closeButton.disabled = false;
        closeButton.focus();
      }
    });
  }

  setShowSuccess(showSuccess: boolean) {
    this.showSuccess.set(showSuccess);
  }

  cancelUpload() {
    this.store.dispatch(SignalsActions.signalsUploadCancellationRequested());
    this.dialogRef.close();
  }
}
