<div class="flex w-full flex-col gap-4">
  @if (showDescription()) {
    <mat-card class="bg-scheme-surface w-full">
      <mat-card-content>
        <span class="text-sm font-normal">
          {{ description() }}
        </span>
      </mat-card-content>
    </mat-card>
  }
  <div class="flex w-full flex-row flex-wrap gap-4">
    @for (tileData of tilesData(); track tileData.content) {
      <app-metadata-tile [data]="tileData" />
    }
  </div>
</div>
