import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { realtimeFeature } from '@features/realtime/shared/store/realtime.feature';
import {
  computationInProgress,
  ComputationStepProgress,
  isComputationStep,
  notComputed,
} from '@features/signal-report/shared/interface/computation-step-progress';
import { Store } from '@ngrx/store';
import { LoadableItem, loaded, loading, loadingError, notFound } from '@shared/interfaces/loading-state';

import { ResultUnavailableDisplayComponent } from './result-unavailable-display/result-unavailable-display.component';

@Component({
  selector: 'app-result-unavailable',
  imports: [ResultUnavailableDisplayComponent],
  templateUrl: './result-unavailable.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResultUnavailableComponent {
  public loadableData = input.required<LoadableItem>();
  public connectedToLiveUpdates = this.store.selectSignal(realtimeFeature.selectIsConnected);

  public loaded = computed(() => loaded(this.loadableData()));
  public loading = computed(() => loading(this.loadableData()));

  public error = computed(() => loadingError(this.loadableData()));
  public notFound = computed(() => notFound(this.loadableData()));

  public inProgress = computed(() => {
    const loadableData = this.loadableData();

    if (isComputationStep(loadableData)) {
      return computationInProgress(loadableData);
    } else {
      return true;
    }
  });

  public notComputed = computed(() => {
    const loadableData = this.loadableData();

    if (isComputationStep(loadableData)) {
      return notComputed(loadableData);
    } else {
      return false;
    }
  });

  public unknownProgress = computed(() => {
    const loadableData = this.loadableData();

    if (isComputationStep(loadableData)) {
      return loadableData.progress === ComputationStepProgress.Unknown;
    } else {
      return false;
    }
  });

  constructor(private store: Store) {}
}
