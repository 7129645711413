import { Component, effect, signal } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { MaterialModule } from '@modules/material.module';
import { SidenavComponent } from '@navigation/sidenav/sidenav.component';

const EXPANDED_STORED_NAME = 'astrion-app-root-sidenavExpanded';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  imports: [RouterOutlet, MaterialModule, SidenavComponent],
})
export class AppComponent {
  sidenavExpanded = signal<boolean>(this.storedSidenavExpansion);

  toggleSidenavExpansion = () => {
    this.sidenavExpanded.update(value => !value);
  };

  constructor() {
    effect(() => {
      this.storedSidenavExpansion = this.sidenavExpanded();
    });
  }

  private get storedSidenavExpansion() {
    return (localStorage.getItem(EXPANDED_STORED_NAME) ?? 'true') === 'true';
  }

  private set storedSidenavExpansion(expanded: boolean) {
    localStorage.setItem(EXPANDED_STORED_NAME, expanded ? 'true' : 'false');
  }
}
