@let _filename = filename();

@let _timeSaturation = timeSaturation();
@let _shannonTest = shannonTest();
@let _stationarity = stationarity();

<div class="flex w-full flex-col gap-8">
  <div class="flex flex-initial flex-col">
    @if (ready(_timeSaturation) && samplesId()) {
      <app-report-sub-section [subSectionData]="timeSaturationSubSection" class="w-full">
        <app-time-saturation
          [filename]="_filename"
          [timeSaturation]="_timeSaturation.data!"
          [samplesId]="samplesId()!" />
      </app-report-sub-section>
    } @else {
      <app-result-unavailable [loadableData]="_timeSaturation" class="h-32 w-full" />
    }
  </div>
  <div class="flex flex-initial flex-col">
    @if (ready(_shannonTest)) {
      <app-report-sub-section [subSectionData]="shannonSubSection" class="w-full">
        <app-shannon-sampling-test [filename]="_filename" [shannonTest]="_shannonTest.data!" />
      </app-report-sub-section>
    } @else {
      <app-result-unavailable [loadableData]="_shannonTest" class="h-32 w-full" />
    }
  </div>
  <div class="flex flex-initial flex-col">
    @if (ready(_stationarity)) {
      <app-report-sub-section [subSectionData]="stationaritySubSection" class="w-full">
        <app-stationarity [stationarity]="_stationarity.data!" [filename]="_filename" />
      </app-report-sub-section>
    } @else {
      <app-result-unavailable [loadableData]="_stationarity" class="h-32 w-full" />
    }
  </div>
</div>
