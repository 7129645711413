import { ChangeDetectionStrategy, Component, computed, output } from '@angular/core';
import { ResultUnavailableComponent } from '@components/result-unavailable/result-unavailable.component';
import { DataValidationSummaryComponent } from '@features/data-validation/shared/components/data-validation-summary/data-validation-summary.component';
import { ReportSignalGraphComponent } from '@features/signal-overview/components/report-signal-graph/report-signal-graph.component';
import { SignalOverviewMetadataComponent } from '@features/signal-overview/components/signal-overview-metadata/signal-overview-metadata.component';
import { signalOverviewFeature } from '@features/signal-overview/shared/store/signal-overview.feature';
import { signalReportFeature } from '@features/signal-report/shared/store/signal-report.feature';
import { Store } from '@ngrx/store';
import { LoadableItem, loaded } from '@shared/interfaces/loading-state';

@Component({
  selector: 'app-report-overview',
  imports: [
    ReportSignalGraphComponent,
    SignalOverviewMetadataComponent,
    ResultUnavailableComponent,
    DataValidationSummaryComponent,
  ],
  templateUrl: './report-overview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportOverviewComponent {
  public navigationToAnchorRequested = output<string>();

  public ready = (loadableItem?: LoadableItem): boolean => loadableItem !== undefined && loaded(loadableItem!);

  private loadingState = this.store.selectSignal(signalOverviewFeature.selectLoadingState);

  loadableItem = computed(() => {
    return {
      loadingState: this.loadingState(),
    };
  });

  sensor = this.store.selectSignal(signalReportFeature.selectSignalSensor);
  signalFilename = this.store.selectSignal(signalReportFeature.selectNameWithoutExtension);

  private sensorTitlePart = computed(() => {
    const sensor = this.sensor();

    if (sensor === undefined) {
      return '';
    }

    return `${sensor.name} - `;
  });

  public signalGraphTitle = computed(() => `${this.sensorTitlePart()}${this.signalFilename()}`);

  signalBytes = this.store.selectSignal(signalOverviewFeature.selectSignalBytes);
  signalMetadata = this.store.selectSignal(signalOverviewFeature.selectSignalMetadata);

  hasDescription = computed(() => {
    const metadata = this.signalMetadata();

    if (metadata === undefined) {
      return false;
    }

    return metadata.description.length > 0;
  });

  constructor(private store: Store) {}
}
