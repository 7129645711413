<div class="flex w-full flex-col gap-4">
  <app-data-validation-summary (navigationToAnchorRequested)="navigationToAnchorRequested.emit($event)" />

  <div class="flex flex-initial flex-col">
    @if (ready(signalBytes())) {
      <app-report-signal-graph
        [samplesId]="signalBytes()!.data!"
        [graphTitle]="signalGraphTitle()"
        [extendVertically]="!hasDescription()" />
    } @else {
      <app-result-unavailable [loadableData]="signalBytes() ? signalBytes()! : loadableItem()" class="h-32 w-full" />
    }
  </div>

  <div class="flex flex-initial flex-col">
    @if (ready(loadableItem())) {
      <app-signal-overview-metadata
        class="flex-none"
        [metadata]="signalMetadata()!"
        [showDescription]="hasDescription()" />
    } @else {
      <app-result-unavailable [loadableData]="loadableItem()" class="h-32 w-full" />
    }
  </div>
</div>
