@let _tileContent = tileContent();

<mat-card
  class="bg-scheme-surface flex h-20 w-fit min-w-[11rem] flex-col"
  [appHoverCopy]="_tileContent"
  [iconPosition]="overlayIconPosition">
  <mat-card-header class="flex-none">
    <span class="text-xs font-medium">{{ tileTitle() }}</span>
  </mat-card-header>
  <mat-card-content class="flex flex-grow flex-col justify-end">
    <span class="fg-primary block text-xl font-normal">{{ _tileContent }}</span>
  </mat-card-content>
</mat-card>
