import { Component, model, signal } from '@angular/core';
import { MaterialModule } from '@modules/material.module';
import { harmonicsModeName, peakModeName, ModeName } from '../shared/interfaces';

@Component({
  selector: 'app-peaks-harmonics-selection',
  imports: [MaterialModule],
  templateUrl: './peaks-harmonics-selection.component.html',
})
export class PeaksHarmonicsSelectionComponent {
  public selectedMode = model.required<ModeName>();

  public availableModes = [
    { value: peakModeName, name: 'Peaks', checked: signal<boolean>(true) },
    { value: harmonicsModeName, name: 'Harmonic families', checked: signal<boolean>(false) },
  ];

  public selectMode = (mode: ModeName) => {
    if (mode === this.selectedMode()) {
      return;
    }

    this.availableModes.forEach(availableMode => {
      if (availableMode.value !== mode) {
        availableMode.checked.set(false);
      } else {
        availableMode.checked.set(true);
      }
    });

    this.selectedMode.set(mode);
  };
}
