import { Component, input, output } from '@angular/core';
import { FixedSizeItemVirtualScrollTableComponent } from '../../fixed-size-item-virtual-scroll-table/fixed-size-item-virtual-scroll-table.component';
import { ColDef, TableOptions } from '../../fixed-size-item-virtual-scroll-table/interfaces/interfaces';
import { HarmonicSerie } from '@features/peak-identification/shared/interface/harmonic-series';
import { ColoredHarmonicSerie } from '../shared/interfaces';

const harmonicTableColumns: ColDef<ColoredHarmonicSerie>[] = [
  {
    name: 'Frequency (Hz)',
    propertyName: 'fundamentalFrequency',
    sortable: true,
    formattedPropertyValue: (serie: HarmonicSerie): string => serie.fundamentalFrequency.toFixed(2),
    tooltip: (serie: HarmonicSerie): string => {
      const firstPeakRank = serie.harmonics[0].rank;

      if (firstPeakRank === 1) {
        return '';
      }

      return `Theoretical frequency, the serie was identified starting at rank ${firstPeakRank}`;
    },
  },
  {
    name: 'Harmonics count',
    propertyName: 'harmonicsCount',
    sortable: true,
    formattedPropertyValue: (serie: HarmonicSerie): string => serie.harmonicsCount.toString(),
  },
  {
    name: 'Energy',
    propertyName: 'energy',
    sortable: true,
    formattedPropertyValue: (serie: HarmonicSerie): string => serie.energy.toExponential(4),
  },
  {
    name: 'Density',
    propertyName: 'density',
    sortable: true,
    formattedPropertyValue: (serie: HarmonicSerie): string => serie.density.toFixed(5),
  },
  {
    name: 'Regularity',
    propertyName: 'regularity',
    sortable: true,
    formattedPropertyValue: (serie: HarmonicSerie): string => serie.regularity.toFixed(4),
  },
  {
    name: 'Richness',
    propertyName: 'richness',
    sortable: true,
    formattedPropertyValue: (serie: HarmonicSerie): string => serie.richness.toFixed(5),
  },
  {
    name: 'THD',
    propertyName: 'thd',
    sortable: true,
    formattedPropertyValue: (serie: HarmonicSerie): string => (serie.thd === 0 ? '-' : serie.thd.toFixed(5)),
  },
];

@Component({
  selector: 'app-harmonics-table',
  imports: [FixedSizeItemVirtualScrollTableComponent],
  templateUrl: './harmonics-table.component.html',
})
export class HarmonicsTableComponent {
  public harmonicSeries = input.required<ColoredHarmonicSerie[]>();

  public selectionChanged = output<ColoredHarmonicSerie[]>();

  public readonly harmonicsTableOptions: TableOptions<number, ColoredHarmonicSerie> = {
    itemIdRetrieveFn: (data: HarmonicSerie) => data.fundamentalFrequency,
    selectionBackgrounColorRetrieveFn: (data: ColoredHarmonicSerie) => `${data.color}9A`,
    columnDefinitions: harmonicTableColumns,
    rowHeight: 60,
    emptyDataMessage: 'No harmonic serie matches the current criteria.',
    rowSelectionConfiguration: {
      placement: 'after',
    },
    minBufferedItemsCount: 10,
    maxAdditionnalBufferedItemsCount: 10,
  };
}
