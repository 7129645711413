import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as Sentry from '@sentry/angular';
import { map, switchMap } from 'rxjs/operators';

import { buildProjectsFromClaims, Project } from '../interfaces/project';
import { User } from '../interfaces/user';
import { AuthService } from '../services/auth.service';
import { JwtService } from '../services/jwt.service';
import { ProjectRouter } from '../services/projectRouter';
import { AuthActions } from './auth.actions';

@Injectable()
export class AuthEffects {
  tryLoginEffect$ = createEffect(() => {
    return this.action$.pipe(
      ofType(AuthActions.loginRequested),
      switchMap(({ redirectUri }: { redirectUri?: string }) =>
        this.authService.login(redirectUri).pipe(
          map(() => {
            return AuthActions.tokenFetchRequested();
          })
        )
      )
    );
  });

  tryFetchTokenEffect$ = createEffect(() => {
    return this.action$.pipe(
      ofType(AuthActions.tokenFetchRequested),
      switchMap(() =>
        this.authService.fetchToken().pipe(
          map((token: string | null) => {
            if (token !== null) {
              const id = this.jwtService.getStringClaim(token, 'sub');
              const username = this.jwtService.getStringClaim(token, 'name');
              const given_name = this.jwtService.getStringClaim(token, 'given_name');
              const family_name = this.jwtService.getStringClaim(token, 'family_name');
              const email = this.jwtService.getStringClaim(token, 'email');
              const tenantId = this.jwtService.getStringClaim(token, 'tenant-id');
              const projectClaims = this.jwtService.getArrayClaim(token, 'projects');
              const projects = buildProjectsFromClaims(projectClaims);
              const user: User = { id, username, given_name, family_name, email, tenantId };
              return AuthActions.loggedIn({ user: user, projects });
            } else {
              return AuthActions.loginFailed();
            }
          })
        )
      )
    );
  });

  loginSuccessEffect$ = createEffect(() => {
    return this.action$.pipe(
      ofType(AuthActions.loggedIn),
      map(({ projects }: { projects: Project[] }) => {
        const localProjectStr = localStorage.getItem('chosenProject');
        const localProject = (localProjectStr && (JSON.parse(localProjectStr) as Project)) || null;
        if (projects.find(p => p.id == localProject?.id)) {
          return AuthActions.projectChangeRequested({ project: localProject });
        } else if (projects.length == 1) {
          return AuthActions.projectChangeRequested({ project: projects[0] });
        } else {
          // let the guard redirect to choose project if necessary
          return AuthActions.skipped();
        }
      })
    );
  });

  chooseProjectEffect$ = createEffect(() => {
    return this.action$.pipe(
      ofType(AuthActions.projectChangeRequested),
      map(({ project, redirectTo }: { project: Project | null; redirectTo?: string | null }) => {
        const prevProjectString = localStorage.getItem('chosenProject');
        const prevProject = prevProjectString ? JSON.parse(prevProjectString) : null;
        const changed = prevProject?.id !== project?.id;
        if (changed) {
          if (project !== null) {
            localStorage.setItem('chosenProject', JSON.stringify(project));
          } else {
            localStorage.removeItem('chosenProject');
          }
        }
        if (redirectTo) {
          this.router.navigateByUrl(redirectTo);
        }
        return changed ? AuthActions.projectChanged({ project }) : AuthActions.skipped();
      })
    );
  });

  tryLogoutEffect$ = createEffect(() => {
    return this.action$.pipe(
      ofType(AuthActions.logoutRequested),
      switchMap(({ redirectUri }: { redirectUri: string }) =>
        this.authService.logout(redirectUri).pipe(
          map(() => {
            return AuthActions.loggedOut();
          })
        )
      )
    );
  });

  sentryOnLoginEffect$ = createEffect(() => {
    return this.action$.pipe(
      ofType(AuthActions.loggedIn),
      map(({ user }) => {
        // ugly but they use "name" instead of the correct "username" in the showReportDialog
        Sentry.setUser({ ...user, name: user.username });
        Sentry.setTag('tenant_id', user.tenantId);
        return AuthActions.skipped();
      })
    );
  });

  sentryOnLogoutEffect$ = createEffect(() => {
    return this.action$.pipe(
      ofType(AuthActions.loggedOut),
      map(() => {
        Sentry.setUser(null);
        Sentry.setTag('tenant_id', undefined);
        return AuthActions.skipped();
      })
    );
  });

  sentryOnProjectChangeRequestedEffect$ = createEffect(() => {
    return this.action$.pipe(
      ofType(AuthActions.projectChangeRequested),
      map(({ project }) => {
        Sentry.setTag('project_id', project?.id);
        Sentry.setTag('project_name', project?.name);

        if (!project || !project.roles) {
          Sentry.setTag('project_role', undefined);
        } else {
          if (project.roles.length > 0) {
            // TODO support multiple roles.
            // Sentry does not support multiple tag values
            Sentry.setTag('project_role', project.roles[0]);
          }
        }
        return AuthActions.skipped();
      })
    );
  });

  constructor(
    private action$: Actions,
    private authService: AuthService,
    private jwtService: JwtService,
    private router: ProjectRouter
  ) {}
}
