@let _showingAll = showingAll();

<app-vertical-graphs-wrapper
  [name]="filename()"
  [graphsData]="currentCyclesData()"
  [graphHeight]="graphHeight()"
  yTitle="PSD"
  xTitle="Frequency (Hz)"
  [yMeanTicks]="yMeanTicks()"
  [yScale]="yScale()"
  [lines]="currentCyclesLines()"
  [showSettings]="true"
  [showExportImage]="!_showingAll"
  [showInfos]="displayAdditionalData()"
  [yAxisLegendLinear]="true"
  [imageExportOptions]="imageExportOptions()"
  [collapsable]="false">
  <app-peaks-graph-interactive-legend
    class="mx-8"
    [(logScale)]="logScale"
    [curveOptions]="curveOptions"
    [peaks]="_showingAll ? [] : currentCyclePeaks()"
    (peaksPredicateChanged)="peaksFilter.set($event)"
    settings />
  <app-estimation-arrays
    class="mx-8"
    [cycleIndex]="activeCycleIndex()"
    [cycleSpectrumData]="currentCycleSpectrumData()"
    [cycleNoiseData]="currentCycleNoiseData()"
    infos />
</app-vertical-graphs-wrapper>

<div class="flex w-full flex-col">
  @if (!_showingAll) {
    <app-peaks-table class="mt-2 h-[60vh]" [peaks]="currentCyclePeaks()" [filterPredicate]="peaksFilter()" />
  }
</div>
