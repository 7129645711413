import { Component, effect, ElementRef, input, OnInit, output, signal, viewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ReportSection } from '@features/signal-report/shared/interface/report-structure';
import { SignalReportActions } from '@features/signal-report/shared/store/signal-report.actions';
import { MaterialModule } from '@modules/material.module';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { outputToObservable } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-report-section',
  imports: [MaterialModule],
  templateUrl: './report-section.component.html',
})
export class ReportSectionComponent implements OnInit {
  public sectionData = input.required<ReportSection>();

  public expansionToggled = output<boolean>();

  public expansionPanel = viewChild.required(MatExpansionPanel);

  public nativeElement: HTMLElement;

  public isExpanded = signal<boolean>(true);

  constructor(
    elementRef: ElementRef,
    private store: Store
  ) {
    this.nativeElement = elementRef.nativeElement;

    effect(async () => {
      const expansionPanel = this.expansionPanel();

      expansionPanel.afterExpand.subscribe(() => this.isExpanded.set(true));
      expansionPanel.afterCollapse.subscribe(() => this.isExpanded.set(false));

      if (!expansionPanel.expanded) {
        await firstValueFrom(expansionPanel.opened);
      }

      this.isExpanded.set(expansionPanel.expanded);
    });

    effect(() => {
      this.expansionToggled.emit(this.isExpanded());
    });
  }

  ngOnInit(): void {
    this.store.dispatch(
      SignalReportActions.sectionRegistration({
        anchorId: this.sectionData().anchorId,
        htmlElement: this.nativeElement,
        sectionExpansionCallback: async () => {
          if (!this.isExpanded()) {
            const expanded = firstValueFrom(outputToObservable(this.expansionToggled));

            this.expand();

            await expanded;
          }
        },
      })
    );
  }

  public expand = () => {
    this.expansionPanel().open();
  };
}
