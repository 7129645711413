import { Component } from '@angular/core';
import { PathBarComponent } from '@components/path-bar/path-bar.component';
import { ProjectRouter } from '@features/auth/shared/services/projectRouter';
import { authFeature } from '@features/auth/shared/store/auth.feature';
import { Equipment } from '@features/equipments/shared/interfaces/equipment.interface';
import { EquipmentsActions } from '@features/equipments/shared/store/equipments.actions';
import { equipmentsFeature } from '@features/equipments/shared/store/equipments.feature';
import { Store } from '@ngrx/store';
import { OperationOverviewComponent } from '../../components/operation-overview/operation-overview.component';
import { OperationComponent } from '../../components/operation/operation.component';

@Component({
  selector: 'app-operation-view',
  imports: [PathBarComponent, OperationComponent, OperationOverviewComponent],
  templateUrl: './operation-view.component.html',
  styles: ``,
})
export class OperationViewComponent {
  public canEdit = this.store.selectSignal(authFeature.selectCanEdit);
  public isLoading = this.store.selectSignal(equipmentsFeature.selectIsLoading);
  public equipments = this.store.selectSignal(equipmentsFeature.selectEquipments);

  constructor(
    private store: Store,
    private router: ProjectRouter
  ) {
    this.store.dispatch(EquipmentsActions.refreshIfNotInit());
  }

  refresh() {
    this.store.dispatch(EquipmentsActions.equipmentsFetchRequested());
  }

  equipmentClicked(equipment: Equipment) {
    this.router.navigateByUrl(`/equipment/${equipment.id}`);
  }
}
