<app-graph-wrapper-base
  [name]="filename()"
  class="flex w-full flex-col"
  [hasData]="true"
  xTitle="Frequency (Hz)"
  [showSettings]="true"
  [collapsable]="false"
  [graph]="graph"
  [imageExportOptions]="imageExportOptions()"
  graphHeight="70vh">
  <div class="relative h-full w-full" graph>
    <lib-gl-graph
      #graph
      class="absolute inset-0 bg-white"
      [data]="graphData()"
      id="spectral-analysis-graph-id"
      xTitle="Frequency (Hz)"
      yTitle="PSD"
      [yScale]="yScale()"
      [yAxisLegendLinear]="true"
      [lines]="visiblePeakLines()"
      [syncWithVerticalLines]="true"
      (canvasDimensionsChanged)="graphCanvasDimensions.set($event)"
      (xAxisRangeChanged)="visibleFrequenciesRange.set($event)" />

    @if (inHarmonicsMode()) {
      <app-harmonics-markers
        class="absolute"
        [ngStyle]="axisOverlayStyle()"
        [harmonics]="emphasizedHarmonics()"
        [canvasDimensions]="graphCanvasDimensions()"
        [frequenciesRange]="visibleFrequenciesRange()" />
    }
  </div>

  @if (inHarmonicsMode()) {
    <app-hidden-harmonics-warning [hiddenHarmonicsCount]="hiddenSelectedHarmonicsCount()" headers />
  }
  <app-peaks-graph-interactive-legend
    class="mx-8"
    [logScale]="logScale()"
    (logScaleChange)="logScale.set($event)"
    [curveOptions]="curveOptions"
    [peaks]="peaks()"
    (peaksPredicateChanged)="peaksVisibilityFilter.set($event)"
    settings />
</app-graph-wrapper-base>
