import { Injectable } from '@angular/core';
import { NavigationBehaviorOptions, NavigationExtras, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { authFeature } from '../store/auth.feature';

@Injectable()
export class ProjectRouter {
  chosenProject = this.store.selectSignal(authFeature.selectChosenProject);

  constructor(
    private router: Router,
    private store: Store
  ) {}

  navigateByUrl(url: string | UrlTree, extras?: NavigationBehaviorOptions): Promise<boolean> {
    const choosenProject = this.chosenProject();
    if (choosenProject) {
      const withProject = `/${choosenProject.name}${url}`;
      return this.router.navigateByUrl(withProject, extras);
    }

    return this.router.navigateByUrl(this.router.parseUrl('/auth/chooseproject?redirectTo=' + url), extras);
  }

  navigate(commands: unknown[], extras?: NavigationExtras): Promise<boolean> {
    return this.router.navigate(commands, extras);
  }
}
