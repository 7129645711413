import { Component, input, output } from '@angular/core';
import { MaterialModule } from '@modules/material.module';
import { FilterDialogService } from '@services/filter-dialog.service';
import { DateFilterModel } from '@shared/interfaces/filter-model';

@Component({
  selector: 'app-date-filter',
  providers: [FilterDialogService],
  imports: [MaterialModule],
  templateUrl: './filter.component.html',
  styles: ``,
})
export class DateFilterComponent {
  values = input.required<Date[]>();
  inputFilter = input.required<DateFilterModel | undefined>();
  outputFilter = output<DateFilterModel | undefined>();

  constructor(private filterDialogService: FilterDialogService) {}

  async openFilterDialog(event: MouseEvent) {
    event.stopPropagation();

    const values = this.values();
    const min = values.reduce((a, b) => (a < b ? a : b));
    const max = values.reduce((a, b) => (a > b ? a : b));

    const filter = await this.filterDialogService.openDateFilter(
      { min: min, max: max, filter: this.inputFilter() },
      event.clientX,
      event.clientY
    );

    this.outputFilter.emit(filter);
  }
}
