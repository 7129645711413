import { ChangeDetectionStrategy, Component, effect, HostBinding, input } from '@angular/core';

@Component({
  selector: 'app-border-gradient',
  imports: [],
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BorderGradientComponent {
  public position = input.required<'top' | 'bottom'>();

  @HostBinding('class') classAttribute = 'absolute left-0 h-[20px] w-full from-slate-50 to-transparent';
  @HostBinding('class.top-0') top0 = false;
  @HostBinding('class.bottom-0') bottom0 = false;
  @HostBinding('class.bg-gradient-to-t') bgGradientToTop = false;
  @HostBinding('class.bg-gradient-to-b') bgGradientToBottom = false;

  constructor() {
    effect(() => {
      const position = this.position();

      this.top0 = position === 'top';
      this.bottom0 = !this.top0;
      this.bgGradientToTop = position === 'bottom';
      this.bgGradientToBottom = !this.bgGradientToTop;
    });
  }
}
