import { Component, computed, input, viewChild } from '@angular/core';
import { Colormap, GlGraphComponent, GraphHeatmap, GraphInput } from '@astrion-webtools/graph';
import { MaterialModule } from '@modules/material.module';
import { GraphExportService } from '@services/graph-export.service';
import { ASTRION_INDEXEDDB_NAME, ASTRION_INDEXEDDB_TABLES } from '@shared/constants/astrion-indexeddb';
import { downloadImageBlob } from '@tools/utilities/download';

@Component({
  selector: 'app-stationarity-heatmap',
  standalone: true,
  imports: [GlGraphComponent, MaterialModule],
  templateUrl: './stationarity-heatmap.component.html',
  styles: ``,
})
export class StationarityHeatmapComponent {
  id = input.required<string>();
  filename = input.required<string>();
  heatmap = input.required<GraphHeatmap | undefined>();
  graphTitle = input.required<string>();
  colormap = input.required<Colormap | string>();
  min = input.required<number>();
  max = input.required<number>();

  graph = viewChild.required<GlGraphComponent>('graph');

  data = computed<GraphInput>(() => {
    const heatmap = this.heatmap();
    return {
      heatmaps: heatmap ? [heatmap] : undefined,
      dbName: ASTRION_INDEXEDDB_NAME,
      storeName: ASTRION_INDEXEDDB_TABLES.stationarityTestImages,
    };
  });

  constructor(private exportService: GraphExportService) {}

  async onExport() {
    const image = await this.exportService.openImageExportDialog(this.graph(), {
      title: `${this.filename()} - ${this.graphTitle()}`,
      colormaps: {
        colormap: this.colormap(),
        min: this.min(),
        max: this.max(),
      },
    });
    if (image) {
      downloadImageBlob('image.png', image);
    }
  }
}
