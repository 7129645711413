import { ChangeDetectionStrategy, Component, effect, Inject, viewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MaterialModule } from '@modules/material.module';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MaterialModule],
})
export class ConfirmDialogComponent {
  public cancelButton = viewChild('cancelButton', { read: HTMLButtonElement });

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public options: ConfirmDialogOptions
  ) {
    effect(() => {
      // cdkFocusInitial in the template do not work for an unknown reason
      const cancelButton = this.cancelButton();

      if (cancelButton === undefined) {
        return;
      }

      cancelButton.focus();
    });
  }
}

export interface ConfirmDialogOptions {
  title: string;
  descriptions: string[];
}
