import { Component, ElementRef, input, OnInit } from '@angular/core';
import { ReportSubSection } from '@features/signal-report/shared/interface/report-structure';
import { SignalReportActions } from '@features/signal-report/shared/store/signal-report.actions';
import { MaterialModule } from '@modules/material.module';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-report-sub-section',
  imports: [MaterialModule],
  templateUrl: './report-sub-section.component.html',
})
export class ReportSubSectionComponent implements OnInit {
  public subSectionData = input.required<ReportSubSection>();

  public nativeElement: HTMLElement;

  constructor(
    elementRef: ElementRef,
    private store: Store
  ) {
    this.nativeElement = elementRef.nativeElement;
  }

  ngOnInit(): void {
    this.store.dispatch(
      SignalReportActions.subSectionRegistration({
        anchorId: this.subSectionData().anchorId,
        htmlElement: this.nativeElement,
      })
    );
  }
}
