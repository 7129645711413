import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MaterialModule } from '@modules/material.module';

@Component({
  selector: 'app-shannon-table-check',
  imports: [MaterialModule],
  templateUrl: './shannon-table-check.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShannonTableCheckComponent {
  success = input.required<boolean>();
}
