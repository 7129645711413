import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';
import { Project } from '@features/auth/shared/interfaces/project';
import { AuthActions } from '@features/auth/shared/store/auth.actions';
import { authFeature } from '@features/auth/shared/store/auth.feature';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-chooseproject',
  templateUrl: './chooseproject.component.html',
  imports: [CommonModule, MatButtonModule, MatOptionModule, MatFormFieldModule, MatSelectModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChooseProjectComponent {
  public projects = this.store.selectSignal(authFeature.selectProjects);
  public selectedProject: Project | undefined;
  public redirectTo?: string | null;

  public constructor(
    private store: Store,
    private route: ActivatedRoute
  ) {
    this.route.queryParamMap.subscribe(pm => {
      this.redirectTo = pm.get('redirectTo');
    });
  }

  public chooseProject(): void {
    if (this.selectedProject !== undefined) {
      this.store.dispatch(
        AuthActions.projectChangeRequested({
          project: this.selectedProject,
          redirectTo: this.redirectTo,
        })
      );
    }
  }
}
