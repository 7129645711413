import { ChangeDetectionStrategy, Component, computed } from '@angular/core';
import { ResultUnavailableComponent } from '@components/result-unavailable/result-unavailable.component';
import { ShannonSamplingTestComponent } from '@features/data-validation/components/shannon-sampling-test/shannon-sampling-test.component';
import { StationarityComponent } from '@features/data-validation/components/stationarity/stationarity.component';
import { TimeSaturationComponent } from '@features/data-validation/components/time-saturation/time-saturation.component';
import { dataValidationFeature } from '@features/data-validation/shared/store/data-validation.feature';
import { signalOverviewFeature } from '@features/signal-overview/shared/store/signal-overview.feature';
import {
  computationCompleted,
  ComputationStep,
} from '@features/signal-report/shared/interface/computation-step-progress';
import { Store } from '@ngrx/store';
import { ReportSubSectionComponent } from '../report-sub-section/report-sub-section.component';
import { signalReportFeature } from '@features/signal-report/shared/store/signal-report.feature';
import { DATA_VALIDATION_SECTION } from '@features/signal-report/shared/interface/report-structure';

@Component({
  selector: 'app-report-data-validation',
  imports: [
    TimeSaturationComponent,
    ShannonSamplingTestComponent,
    StationarityComponent,
    ResultUnavailableComponent,
    ReportSubSectionComponent,
  ],
  templateUrl: './report-data-validation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportDataValidationComponent {
  public filename = this.store.selectSignal(signalReportFeature.selectNameWithoutExtension);

  signalData = this.store.selectSignal(signalOverviewFeature.selectSignalData);

  samplesId = computed(() => this.signalData()?.content.data);

  public ready = (step: ComputationStep): boolean => computationCompleted(step);

  public timeSaturation = this.store.selectSignal(dataValidationFeature.selectSaturationTest);
  public shannonTest = this.store.selectSignal(dataValidationFeature.selectSamplingTest);
  public stationarity = this.store.selectSignal(dataValidationFeature.selectStationarityTest);

  public timeSaturationSubSection = DATA_VALIDATION_SECTION.subsections['timeSaturation'];
  public shannonSubSection = DATA_VALIDATION_SECTION.subsections['shannon'];
  public stationaritySubSection = DATA_VALIDATION_SECTION.subsections['stationarity'];

  constructor(private store: Store) {}
}
