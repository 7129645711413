import { CommonModule } from '@angular/common';
import { Component, computed, effect, Inject, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlGraphComponent, ImageExportOptions } from '@astrion-webtools/graph';
import { MaterialModule } from '@modules/material.module';
import {
  ButtonsAction,
  GraphImageExportDialogButtonsComponent,
} from './graph-image-export-dialog-buttons/graph-image-export-dialog-buttons.component';
import { GraphExportService } from '@services/graph-export.service';
import { BehaviorSubject, debounceTime, Observable, switchMap } from 'rxjs';
import { GraphImageExportDialogPreviewComponent } from './graph-image-export-dialog-preview/graph-image-export-dialog-preview.component';

interface InputData {
  graph: GlGraphComponent;
  componentOptions: Partial<ImageExportOptions>;
}

@Component({
  selector: 'app-graph-image-export-dialog',
  standalone: true,
  imports: [
    MaterialModule,
    FormsModule,
    CommonModule,
    GraphImageExportDialogButtonsComponent,
    GraphImageExportDialogPreviewComponent,
  ],
  templateUrl: './graph-image-export-dialog.component.html',
})
export class GraphImageExportDialogComponent {
  previewOptions$ = new BehaviorSubject<ImageExportOptions | undefined>(undefined);
  previewImage$: Observable<Blob | undefined> = this.previewOptions$.pipe(
    debounceTime(500),
    switchMap(async options => (options ? await this.graph.exportImage(options) : undefined))
  );

  logoImage = signal<HTMLImageElement | undefined>(undefined);

  graph: GlGraphComponent;
  graphId: string;
  componentOptions: Partial<ImageExportOptions>;

  globalDefault = signal<ImageExportOptions>(this.exportService.getDefaultOptions());
  graphOptions = signal<Partial<ImageExportOptions>>({});

  constructor(
    private dialogRef: MatDialogRef<GraphImageExportDialogComponent, Blob | undefined>,
    @Inject(MAT_DIALOG_DATA) public data: InputData,
    private exportService: GraphExportService
  ) {
    this.graph = data.graph;
    this.graphId = data.graph.id();
    this.componentOptions = data.componentOptions;
    this.globalDefault.set({
      ...this.exportService.getDefaultOptions(),
      ...this.componentOptions,
    });
    this.graphOptions.set(this.exportService.getGraphOptions(this.graphId));

    this.setDefaultTitle();
    this.setDefaultSize();
    this.setDefaultFonts();

    const logoImage = new Image();
    logoImage.onload = () => this.logoImage.set(logoImage);
    logoImage.src = '/assets/astrion_for_graph_export.png';

    effect(() => {
      // Update preview when options change
      const options = this.outputOptions();
      if (options.width > 200 && options.height > 200) {
        this.previewOptions$.next(options);
      }
    });
  }

  title = signal<string>('');
  private setDefaultTitle() {
    this.title.set(this.graphOptions().title ?? this.globalDefault().title ?? '');
  }

  width = signal<number>(1280);
  height = signal<number>(720);
  private setDefaultSize() {
    const graphOptions = this.graphOptions();
    const globalDefault = this.globalDefault();
    this.width.set(graphOptions.width ?? globalDefault.width);
    this.height.set(graphOptions.height ?? globalDefault.height);
  }

  titleSize = signal<number>(45);
  axisSize = signal<number>(25);
  ticksSize = signal<number>(18);
  private setDefaultFonts() {
    const graphOptions = this.graphOptions();
    const globalDefault = this.globalDefault();
    const titleFont = graphOptions.titleFont ?? globalDefault.titleFont ?? '45px Arial';
    const axisFont = graphOptions.axisFont ?? globalDefault.axisFont ?? '25px Arial';
    const ticksFont = graphOptions.ticksFont ?? globalDefault.ticksFont ?? '18px Arial';
    this.titleSize.set(decomposeFont(titleFont).size);
    this.titleSize.set(decomposeFont(axisFont).size);
    this.titleSize.set(decomposeFont(ticksFont).size);
  }

  titleFont = computed(() => composeFont(this.titleSize()));
  axisFont = computed(() => composeFont(this.axisSize()));
  ticksFont = computed(() => composeFont(this.ticksSize()));

  titleIsGlobalDefault = computed(() => this.title() === this.globalDefault().title);
  titleIsGraphDefault = computed(() => !this.titleIsGlobalDefault() && this.title() === this.graphOptions().title);

  fontsAreGlobalDefault = computed(() => {
    const globalDefault = this.globalDefault();
    return (
      this.titleFont() === globalDefault.titleFont &&
      this.axisFont() === globalDefault.axisFont &&
      this.ticksFont() === globalDefault.ticksFont
    );
  });
  fontsAreGraphDefault = computed(() => {
    const graphOptions = this.graphOptions();
    return (
      !this.fontsAreGlobalDefault() &&
      this.titleFont() === graphOptions.titleFont &&
      this.axisFont() === graphOptions.axisFont &&
      this.ticksFont() === graphOptions.ticksFont
    );
  });

  sizeIsGlobalDefault = computed(() => {
    const globalDefault = this.globalDefault();
    return this.width() === globalDefault.width && this.height() === globalDefault.height;
  });
  sizeIsGraphDefault = computed(() => {
    const graphOptions = this.graphOptions();
    return !this.sizeIsGlobalDefault() && this.width() === graphOptions.width && this.height() === graphOptions.height;
  });

  outputOptions = computed(() => {
    const globalDefault = this.globalDefault();
    const graphOptions = this.graphOptions();
    const title = this.title();
    const width = this.width();
    const height = this.height();
    const titleFont = this.titleFont();
    const axisFont = this.axisFont();
    const ticksFont = this.ticksFont();
    // Add logo
    const logoWidth = Math.round(width * 0.07);
    const logoHeight = Math.round(logoWidth * 0.24); // logo ratio

    const logoImage = this.logoImage();

    return {
      ...globalDefault,
      ...graphOptions,
      title,
      width,
      height,
      titleFont,
      axisFont,
      ticksFont,
      logo: logoImage
        ? {
            image: logoImage,
            width: logoWidth,
            height: logoHeight,
          }
        : undefined,
    };
  });

  cancel() {
    this.dialogRef.close(undefined);
  }

  async export() {
    const options = this.outputOptions();
    const image = await this.graph.exportImage(options);
    this.dialogRef.close(image);
  }

  actionOnTitle(action: ButtonsAction) {
    switch (action) {
      case ButtonsAction.SaveDefault:
      case ButtonsAction.SaveForGraph: {
        const title = this.title();
        // Save on graph level (as title is defined at component level)
        this.graphOptions.set(
          this.exportService.updateGraphOptions(this.graphId, {
            title,
          })
        );
        break;
      }
      case ButtonsAction.ResetDefault: {
        // remove from graphDefault in service if exists
        // Will trigger linkedSignals
        this.graphOptions.set(this.exportService.deleteGraphOptions(this.graphId, 'title'));
        this.setDefaultTitle();
        break;
      }
      case ButtonsAction.ResetGraph: {
        this.setDefaultTitle();
        break;
      }
    }
  }

  actionOnFonts(action: ButtonsAction) {
    const currentOptions: Partial<ImageExportOptions> = {
      titleFont: this.titleFont(),
      axisFont: this.axisFont(),
      ticksFont: this.ticksFont(),
    };
    switch (action) {
      case ButtonsAction.SaveDefault: {
        this.globalDefault.set({
          ...this.exportService.updateDefaultOptions(currentOptions),
          ...this.componentOptions,
        });
        break;
      }
      case ButtonsAction.SaveForGraph: {
        this.graphOptions.set(this.exportService.updateGraphOptions(this.graphId, currentOptions));
        break;
      }
      case ButtonsAction.ResetDefault: {
        // remove from graphDefault in service if exists
        this.graphOptions.set(
          this.exportService.deleteGraphOptions(this.graphId, ['titleFont', 'axisFont', 'ticksFont'])
        );
        this.setDefaultFonts();
        break;
      }
      case ButtonsAction.ResetGraph: {
        this.setDefaultFonts();
        break;
      }
    }
  }

  actionOnSize(action: ButtonsAction) {
    const currentOptions: Partial<ImageExportOptions> = {
      width: this.width(),
      height: this.height(),
    };
    switch (action) {
      case ButtonsAction.SaveDefault: {
        this.globalDefault.set({
          ...this.exportService.updateDefaultOptions(currentOptions),
          ...this.componentOptions,
        });
        break;
      }
      case ButtonsAction.SaveForGraph: {
        this.graphOptions.set(this.exportService.updateGraphOptions(this.graphId, currentOptions));
        break;
      }
      case ButtonsAction.ResetDefault: {
        // remove from graphDefault in service if exists
        this.graphOptions.set(this.exportService.deleteGraphOptions(this.graphId, ['width', 'height']));
        this.setDefaultSize();
        break;
      }
      case ButtonsAction.ResetGraph: {
        this.setDefaultSize();
        break;
      }
    }
  }
}

const decomposeFont = (font: string): { size: number; font: string } => {
  const splited = font.split(' ').map(x => x);
  return {
    size: parseInt(splited[0].replace('px', '').replace(/ /g, '')),
    font: splited[1],
  };
};

const composeFont = (size: number, font: string = 'Arial'): string => `${size}px ${font}`;
