import { Component, computed, input, output } from '@angular/core';
import { ValidationStepStatus } from '@features/data-validation/shared/interface/validation-step-status';
import { MaterialModule } from '@modules/material.module';
import { DataValidationStatusBadgeComponent } from '../../data-validation-status-badge/data-validation-status-badge.component';
import { HoverGoToDirective } from '@shared/directives/hover-go-to.directive';

export interface SectionTileData {
  name: string;
  validationStatus: ValidationStepStatus;
}

@Component({
  selector: 'app-data-validation-section-tile',
  imports: [MaterialModule, DataValidationStatusBadgeComponent, HoverGoToDirective],
  templateUrl: './data-validation-section-tile.component.html',
})
export class DataValidationSectionTileComponent {
  public data = input.required<SectionTileData>();

  public clicked = output<void>();

  public tileTitle = computed(() => this.data().name);
  public status = computed(() => this.data().validationStatus);
}
