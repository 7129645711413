import { Component, input } from '@angular/core';
import { Colormap, ColormapLegendComponent, DisplayNumberPipe } from '@astrion-webtools/graph';
import { DisplayType } from '@astrion-webtools/graph/lib/tools/pipes/display-number.pipe';

@Component({
  selector: 'app-color-scale',
  imports: [ColormapLegendComponent, DisplayNumberPipe],
  templateUrl: './color-scale.component.html',
})
export class ColorScaleComponent {
  id = input.required<string>();
  colormap = input.required<Colormap | string>();
  min = input.required<number>();
  max = input.required<number>();
  displayType = input<DisplayType>('scientific');
}
