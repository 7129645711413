import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { Progress } from '@app-types/progress.interface';
import { MaterialModule } from '@modules/material.module';
import { FilesizeProgressPipe } from '@tools/pipes/filesize-progress.pipe';

@Component({
  selector: 'app-upload-progress-screen',
  templateUrl: './upload-progress-screen.component.html',
  imports: [MaterialModule, FilesizeProgressPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadProgressScreenComponent {
  public progress = input.required<Progress | null>();
}
