import { SensorId } from '@features/sensors/shared/interfaces/sensor.interface';
import { AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import { LoadingState } from '@shared/interfaces/loading-state';
import { defaultReportAnchors, ReportAnchors } from './utils/section-utils';

export const featureName = 'signal-report';

export interface SignalReportState {
  name: string;
  signalId: AStrionSignalId | null;
  sensorId: SensorId | null;
  loadingState: LoadingState;
  anchors: ReportAnchors;
}

export const SIGNAL_REPORT_INITIAL_STATE: SignalReportState = {
  name: '',
  signalId: null,
  sensorId: null,
  loadingState: LoadingState.Unknown,
  anchors: defaultReportAnchors(),
};
