import { Component, computed, effect, ElementRef, input, model, viewChild } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { getLinePattern, LineStyle } from '@astrion-webtools/graph';
import { MaterialModule } from '@modules/material.module';

export interface ContentStyle {
  color: string;
  dashingPattern?: LineStyle;
}

export type Style = ContentStyle | [ContentStyle, ContentStyle] | [ContentStyle, ContentStyle, ContentStyle];

const isContentStyle = (style: Style): style is ContentStyle => {
  return (style as ContentStyle[]).length === undefined;
};

@Component({
  selector: 'app-content-toggle',
  imports: [MaterialModule],
  templateUrl: './content-toggle.component.html',
})
export class ContentToggleComponent {
  public label = input.required<string>();
  public uncheckDisabled = input<boolean>(false);
  public checked = model.required<boolean>();
  public contentStyle = input<Style | undefined>(undefined);
  public objectsCount = input<number | undefined>(undefined);
  public legendCanvasRef = viewChild<ElementRef<HTMLCanvasElement> | undefined>('main');
  public checkbox = viewChild.required<MatCheckbox>('checkbox');

  private canvas = computed(() => this.legendCanvasRef()?.nativeElement);

  public disabled = computed(() => {
    const checkbox = this.checkbox();

    return this.uncheckDisabled() && checkbox && checkbox.checked;
  });

  public canvasClicked = () => {
    if (this.disabled()) {
      return;
    }

    this.checked.set(!this.checked());
  };

  constructor() {
    effect(() => {
      const canvas = this.canvas();
      const style = this.contentStyle();

      if (!canvas || !style) {
        return;
      }

      canvas.height = 20;
      canvas.width = 35;
      const ctx = canvas.getContext('2d');

      if (!ctx) {
        return;
      }

      if (isContentStyle(style)) {
        ctx.strokeStyle = style.color;
        ctx.lineWidth = 3;
        ctx.setLineDash(getLinePattern(style.dashingPattern));

        ctx.beginPath();
        ctx.moveTo(0, canvas.height / 2);
        ctx.lineTo(canvas.width, canvas.height / 2);

        ctx.stroke();
      } else {
        const styles = style as ContentStyle[];
        const lineWidth = 3 / styles.length;
        const lineHeights = canvas.height / (styles.length + 1);

        styles.forEach((style, index) => {
          ctx.strokeStyle = style.color;
          ctx.lineWidth = lineWidth;
          ctx.setLineDash(getLinePattern(style.dashingPattern));

          const lineHeight = lineHeights * (1 + index);

          ctx.beginPath();
          ctx.moveTo(0, lineHeight);
          ctx.lineTo(canvas.width, lineHeight);

          ctx.stroke();
        });
      }
    });
  }
}
