import { Component, computed, effect, input, model, output, signal, WritableSignal } from '@angular/core';
import { PMC } from '@features/peak-identification/shared/interface/pfa-pmc-thresholds';
import {
  getPeakBottomContentStyle,
  getPeakTopContentStyle,
  PeakTypes,
} from '@features/peak-identification/shared/utils/peak-style';
import { MaterialModule } from '@modules/material.module';

import { ContentStyle, ContentToggleComponent, Style } from '../content-toggle/content-toggle.component';
import {
  countPeaks,
  hasHighPMC,
  hasLowPMC,
  hasNBNoiseDoubtType,
  hasNBType,
  hasNoiseType,
  hasSWNoiseDoubtType,
  hasSWType,
  hasVeryLowPMC,
} from './utils/interactive-legend-utils';
import { FormsModule } from '@angular/forms';

export interface SimplifiedPeak {
  type: string;
  pmc: PMC;
}

export interface PeaksGraphCurve {
  name: string;
  style: Style;
  state: WritableSignal<boolean>;
}

export interface CurvesOptions {
  name: string;
  first: PeaksGraphCurve;
  additional?: PeaksGraphCurve[];
}

@Component({
  selector: 'app-peaks-graph-interactive-legend',
  imports: [MaterialModule, FormsModule, ContentToggleComponent],
  templateUrl: './peaks-graph-interactive-legend.component.html',
})
export class PeaksGraphInteractiveLegendComponent {
  curveOptions = input.required<CurvesOptions>();

  peaks = input<SimplifiedPeak[] | undefined>(undefined);
  peaksPredicateChanged = output<(peak: SimplifiedPeak) => boolean>();

  logScale = model.required<boolean>();
  scaleValue = computed(() => (this.logScale() ? 'log10' : 'linear'));
  scaleChanged(scale: 'linear' | 'log10') {
    this.logScale.set(scale === 'log10');
  }

  constructor() {
    effect(() => {
      const showSW = this.showSW();
      const showNB = this.showNB();
      const showSWNoiseDoubt = this.showSWNoiseDoubt();
      const showNBNoiseDoubt = this.showNBNoiseDoubt();
      const showNoise = this.showNoise();
      const showHighPMC = this.showHighPMC();
      const showLowPMC = this.showLowPMC();
      const showVeryLowPMC = this.showVeryLowPMC();

      const peakPredicate = (peak: SimplifiedPeak) => {
        const typeIsVisible =
          (showSW && hasSWType(peak)) ||
          (showNB && hasNBType(peak)) ||
          (showSWNoiseDoubt && hasSWNoiseDoubtType(peak)) ||
          (showNBNoiseDoubt && hasNBNoiseDoubtType(peak)) ||
          (showNoise && hasNoiseType(peak));

        if (!typeIsVisible) {
          return false;
        }

        const pmcIsVisible =
          (showHighPMC && hasHighPMC(peak)) ||
          (showLowPMC && hasLowPMC(peak)) ||
          (showVeryLowPMC && hasVeryLowPMC(peak));

        return pmcIsVisible;
      };

      this.peaksPredicateChanged.emit(peakPredicate);
    });
  }

  additionalCurves = computed(() => {
    const curveOptions = this.curveOptions();

    if (curveOptions.additional == undefined || curveOptions.additional!.length === 0) {
      return [];
    }

    return [...curveOptions.additional].slice(0, 2);
  });

  hasSecondCurve = computed(() => this.additionalCurves().length > 0);

  hasThirdCurve = computed(() => this.additionalCurves().length > 1);

  curves = computed(() => [this.curveOptions().first, ...this.additionalCurves()]);

  curveToggleDisabled = computed(() => {
    const curveStates = this.curves().map(curve => curve.state());

    return curveStates.filter(curveVisible => curveVisible).length === 1;
  });

  showHighPMC = signal<boolean>(true);
  showLowPMC = signal<boolean>(true);
  showVeryLowPMC = signal<boolean>(true);

  showSW = signal<boolean>(true);
  showNB = signal<boolean>(true);
  showSWNoiseDoubt = signal<boolean>(true);
  showNBNoiseDoubt = signal<boolean>(true);
  showNoise = signal<boolean>(true);
  get swStyle(): ContentStyle {
    return getPeakBottomContentStyle(PeakTypes.SW);
  }

  swCount = computed(() => countPeaks(this.peaks(), hasSWType));

  get nbStyle(): ContentStyle {
    return getPeakBottomContentStyle(PeakTypes.NB);
  }

  nbCount = computed(() => countPeaks(this.peaks(), hasNBType));

  get swNoiseDoubtStyle(): ContentStyle {
    return getPeakBottomContentStyle(PeakTypes.SWNoiseDoubt);
  }

  swNoiseDoubtCount = computed(() => countPeaks(this.peaks(), hasSWNoiseDoubtType));

  get nbNoiseDoubtStyle(): ContentStyle {
    return getPeakBottomContentStyle(PeakTypes.NBNoiseDoubt);
  }

  nbNoiseDoubtCount = computed(() => countPeaks(this.peaks(), hasNBNoiseDoubtType));

  get noisePeaksStyle(): ContentStyle {
    return getPeakBottomContentStyle(PeakTypes.Noise);
  }

  noisePeaksCount = computed(() => countPeaks(this.peaks(), hasNoiseType));

  get highPmcStyle(): ContentStyle {
    return getPeakTopContentStyle(PMC.High);
  }

  highPmcCount = computed(() => countPeaks(this.peaks(), hasHighPMC));

  get lowPmcStyle(): ContentStyle {
    return getPeakTopContentStyle(PMC.Low);
  }

  lowPmcCount = computed(() => countPeaks(this.peaks(), hasLowPMC));

  get veryLowPmcStyle(): ContentStyle {
    return getPeakTopContentStyle(PMC.VeryLow);
  }

  veryLowPmcCount = computed(() => countPeaks(this.peaks(), hasVeryLowPMC));
}
