import { BaseSelectors } from '@app-types/extra-selectors-factory';
import { sensorsFeature } from '@features/sensors/shared/store/sensors.feature';
import {
  featureName as signalOverviewFeatureName,
  SignalOverviewState,
} from '@features/signal-overview/shared/store/signal-overview.state';
import { AStrionSignal, AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureName, SignalReportState } from './signal-report.state';
import { SensorId } from '@features/sensors/shared/interfaces/sensor.interface';
import { equipmentsFeature } from '@features/equipments/shared/store/equipments.feature';

const selectSignalOverview = createFeatureSelector<SignalOverviewState>(signalOverviewFeatureName);

export const extraSelectors = ({
  selectName,
  selectSignalId,
  selectSensorId,
}: BaseSelectors<typeof featureName, SignalReportState>) => ({
  selectSignalSensor: createSelector(selectSensorId, sensorsFeature.selectSensors, (sensorId, sensors) => {
    return sensors.find(s => s.id === sensorId);
  }),

  selectSignalEquipment: createSelector(selectSensorId, equipmentsFeature.selectEquipments, (sensorId, equipments) => {
    return equipments.find(e => e.sensors.find(s => s.id === sensorId));
  }),

  selectSignal: createSelector(
    selectName,
    selectSignalId,
    selectSensorId,
    selectSignalOverview,
    (
      name: string,
      signalId: AStrionSignalId | null,
      sensorId: SensorId | null,
      signalOverview: SignalOverviewState
    ): AStrionSignal | undefined => {
      if (signalId === null || sensorId === null || signalOverview.signalData === undefined) {
        return undefined;
      }

      const metadata = signalOverview.signalData.metadata;

      return {
        id: signalId,
        name,
        sensorId,
        createdAt: metadata.createdAt,
        date: metadata.date,
        samplingFrequency: metadata.samplingFrequency,
        samplesCount: metadata.samplesCount,
        description: metadata.description,
      };
    }
  ),

  selectNameWithoutExtension: createSelector(selectName, (name: string): string => {
    return name.slice(0, name.indexOf('.AStrion'));
  }),
});
