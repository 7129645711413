export const download = (filename: string, text: string) => {
  const element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

const toDataURL = async (imageBlob: Blob): Promise<string> =>
  new Promise(ok => {
    const reader = new FileReader();
    reader.addEventListener('load', () => ok(reader.result as string));
    reader.readAsDataURL(imageBlob);
  });

export const downloadImageBlob = async (filename: string, image: Blob) => {
  const data = await toDataURL(image);

  const element = document.createElement('a');
  element.setAttribute('href', data);
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};
