<mat-dialog-content class="flex flex-col">
  <mat-form-field>
    <mat-label>Enter a date range</mat-label>
    <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
      <input matStartDate formControlName="start" placeholder="Start date" />
      <input matEndDate formControlName="end" placeholder="End date" />
    </mat-date-range-input>
    <mat-datepicker-toggle matIconSuffix [for]="picker" />
    <mat-date-range-picker #picker />

    @if (range.controls.start.hasError('matStartDateInvalid')) {
      <mat-error>Invalid start date</mat-error>
    }
    @if (range.controls.end.hasError('matEndDateInvalid')) {
      <mat-error>Invalid end date</mat-error>
    }
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button class="secondary-button" [mat-dialog-close]>Clear filter</button>
  <span class="grow"></span>
  <button mat-raised-button class="primary-button focused" (click)="closeWithFilter()">OK</button>
</mat-dialog-actions>
