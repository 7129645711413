import { CommonModule } from '@angular/common';
import { Component, computed, input } from '@angular/core';
import { Equipment } from '@features/equipments/shared/interfaces/equipment.interface';
import { MaterialModule } from '@modules/material.module';
import { OverviewCardComponent } from '../../../../shared/components/overview-card/overview-card.component';

@Component({
  selector: 'app-operation-overview',
  imports: [CommonModule, MaterialModule, OverviewCardComponent],
  templateUrl: './operation-overview.component.html',
  styles: ``,
})
export class OperationOverviewComponent {
  public equipmentsCount = computed(() => this.equipments().length);
  equipments = input.required<Equipment[]>();
}
