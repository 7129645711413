<div class="flex h-full flex-col items-center">
  @let _activeStep = activeStep();
  @let _dividerClasses = dividerClasses();
  @for (step of steps(); track step.anchorId; let last = $last; let index = $index) {
    @let _isActive = _activeStep === step;

    <app-step
      [icon]="step.icon"
      [active]="_isActive"
      [matTooltip]="_isActive ? '' : step.name"
      matTooltipPosition="right"
      (click)="stepClicked.emit(step)" />
    @if (!last) {
      <mat-divider [vertical]="true" class="border-outline-variant border-r-2" [ngClass]="_dividerClasses[index]" />
    }
  }
</div>
