import { TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { TrajectoryGraphComponent } from '@components/trajectory-graph/trajectory-graph.component';
import { SensorGraphsService } from '@features/sensor-graphs/shared/service/sensor-graphs.service';
import { SensorId } from '@features/sensors/shared/interfaces/sensor.interface';

@Component({
  selector: 'app-sensor-graphs',
  imports: [TrajectoryGraphComponent, TitleCasePipe],
  providers: [],
  templateUrl: './sensor-graphs.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SensorGraphsComponent {
  id = input.required<string>();
  sensorId = input<SensorId>();
  name = input.required<string>();
  data = computed(() => {
    const sensorId = this.sensorId();
    if (!sensorId) {
      return undefined;
    }
    return this.sensorGraphsService.graphInputSignal(sensorId);
  });

  constructor(private sensorGraphsService: SensorGraphsService) {}

  onDelete() {
    const sensorId = this.sensorId();
    if (!sensorId) {
      return;
    }
    this.sensorGraphsService.clearCurves(sensorId);
  }

  onRemoveCurve(curveName: string) {
    const sensorId = this.sensorId();
    if (!sensorId) {
      return;
    }
    const curveNameToId = this.sensorGraphsService.curveNameToIdSignal(sensorId);
    this.sensorGraphsService.removeCurve(sensorId, curveNameToId()[curveName]);
  }
}
