import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuardFunction } from '@features/auth/auth.guard';
import { AUTH_ROUTES } from '@features/auth/auth.routes';
import { projectGuardFunction } from '@features/auth/project.guard';
import { projectChangeInUrlGuardFunction } from '@features/auth/projectChangeInUrl.guard';
import { EQUIPMENTS_ROUTES } from '@features/equipments/equipments.routes';
import { OperationViewComponent } from '@features/equipments/views/operation-view/operation-view.component';
import { SENSORS_ROUTES } from '@features/sensors/sensors.routes';
import { SIGNAL_REPORT_ROUTES } from '@features/signal-report/signal-report.routes';

const APP_ROUTES: Routes = [
  {
    path: 'auth',
    children: AUTH_ROUTES,
  },
  {
    path: '',
    canActivate: [authGuardFunction, projectChangeInUrlGuardFunction, projectGuardFunction],
    pathMatch: 'prefix',
    children: [
      {
        path: ':projectName/signals/report',
        children: SIGNAL_REPORT_ROUTES,
      },
      {
        path: ':projectName/operation',
        component: OperationViewComponent,
      },
      {
        path: 'operation',
        component: OperationViewComponent,
      },
      {
        path: ':projectName/equipment',
        children: EQUIPMENTS_ROUTES,
      },
      {
        path: ':projectName/sensor',
        children: SENSORS_ROUTES,
      },
      {
        path: '**',
        redirectTo: 'operation',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(APP_ROUTES, {
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
