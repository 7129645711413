import { ChangeDetectionStrategy, Component, computed, input, signal } from '@angular/core';
import { Colormap, GraphCurve, GraphHeatmap } from '@astrion-webtools/graph';
import { StationarityData } from '@features/data-validation/shared/interface/stationarity';

import { StatusBannerComponent } from '../status-banner/status-banner.component';
import { ColorScaleComponent } from './color-scale/color-scale.component';
import { StationarityDetectionProjectionComponent } from './stationarity-detection-projection/stationarity-detection-projection.component';
import { StationarityHeatmapComponent } from './stationarity-heatmap/stationarity-heatmap.component';
import { StationarityVarianceComponent } from './stationarity-variance/stationarity-variance.component';

@Component({
  selector: 'app-stationarity',
  imports: [
    StatusBannerComponent,
    StationarityHeatmapComponent,
    StationarityVarianceComponent,
    StationarityDetectionProjectionComponent,
    ColorScaleComponent,
  ],
  templateUrl: './stationarity.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StationarityComponent {
  public filename = input.required<string>();
  public stationarity = input.required<StationarityData>();

  timeFrequencyColormap = signal<Colormap | string>('LegacyStationaritySpectrogram');
  nonStationarityColormap = signal<Colormap | string>('FaultDetectionProjection');

  timeFrequencyHeatmap = computed<GraphHeatmap | undefined>(() => {
    const stationarity = this.stationarity();
    const dataId = stationarity.spectrogram.image.data;
    if (dataId == undefined) {
      return undefined;
    }

    return {
      id: 'time-frequency-heatmap',
      colormap: this.timeFrequencyColormap(),
      data: {
        indexedDb: {
          id: dataId,
          valuesField: 'data',
          xMinField: 'xMin',
          xMaxField: 'xMax',
          yMinField: 'yMin',
          yMaxField: 'yMax',
        },
      },
    };
  });
  timeFrequencyMinValue = computed(() => this.stationarity().spectrogram.psdMin);
  timeFrequencyMaxvalue = computed(() => this.stationarity().spectrogram.psdMax);

  nonStationarityHeatmap = computed<GraphHeatmap | undefined>(() => {
    const stationarity = this.stationarity();
    const dataId = stationarity.stationarityData.image.data;
    if (dataId == undefined) {
      return undefined;
    }

    return {
      id: 'time-frequency-non-stationarity',
      colormap: this.nonStationarityColormap(),
      data: {
        indexedDb: {
          id: dataId,
          valuesField: 'data',
          xMinField: 'xMin',
          xMaxField: 'xMax',
          yMinField: 'yMin',
          yMaxField: 'yMax',
        },
      },
    };
  });
  nonStationarityMinValue = computed(() => this.stationarity().stationarityData.minValue);
  nonStationarityMaxValue = computed(() => this.stationarity().stationarityData.maxValue);

  kappa = computed<number>(() => this.stationarity().stationarityData.kappa);
  variance = computed<GraphCurve | undefined>(() => {
    const stationarity = this.stationarity();
    const dataId = stationarity.stationarityData.variance.data;
    if (dataId == undefined) {
      return undefined;
    }

    return {
      id: 'stationarity-variance',
      color: '#BD1814',
      data: {
        indexedDb: {
          id: dataId,
          valuesField: 'data',
          yMinField: 'yMin',
          yMaxField: 'yMax',
        },
      },
    };
  });

  projection = computed<GraphCurve | undefined>(() => {
    const stationarity = this.stationarity();
    const dataId = stationarity.stationarityData.projection.data;
    if (dataId == undefined) {
      return undefined;
    }

    return {
      id: 'stationarity-projection',
      displayName: 'Projection',
      color: '#1F618D',
      data: {
        indexedDb: {
          id: dataId,
          valuesField: 'data',
          xMinField: 'xMin',
          xMaxField: 'xMax',
        },
      },
    };
  });

  public statusMessage = computed(() => {
    const stationarity = this.stationarity();

    const nonStationarityPercentage = stationarity.stationarityData.detectionIndex;
    const thresholds = stationarity.thresholds;

    if (nonStationarityPercentage < thresholds.stationarity) {
      return 'Signal is stationary.';
    }

    let message = 'Signal has a ';

    message +=
      nonStationarityPercentage < thresholds.lowNonStationarity
        ? 'low'
        : nonStationarityPercentage < thresholds.mediumNonStationarity
          ? 'medium'
          : 'high';

    message += ` non-stationarity rate of ${nonStationarityPercentage}% (0% corresponds to a stationary signal).`;

    return message;
  });
}
