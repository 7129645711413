@let _hasAdditionalContent = hasAdditionalContent();

<mat-expansion-panel
  class="banner bg-scheme-surface p-4"
  disabled="true"
  [expanded]="panelExpanded"
  [hideToggle]="true">
  <mat-expansion-panel-header
    class="cursor-default bg-transparent p-0"
    [expandedHeight]="'auto'"
    [collapsedHeight]="'auto'">
    <div
      class="grid w-full grid-cols-[max-content_1fr_max-content] items-center gap-x-3"
      [class]="_hasAdditionalContent ? 'grid-cols-[max-content_1fr_max-content]' : 'grid-cols-[max-content_1fr]'">
      <app-data-validation-status-badge [status]="status()" />
      <span class="fg-on-surface-variant -mb-0.5 pr-3 text-sm">{{ message() }}</span>
      @if (_hasAdditionalContent) {
        <button mat-stroked-button class="fg-on-surface-variant w-36 place-self-end" (click)="togglePanel()">
          @if (panelExpanded) {
            Hide details
          } @else {
            Show details
          }
        </button>
      }
    </div>
  </mat-expansion-panel-header>
  <div #additionalContent class="mt-4 rounded-xl border bg-white">
    <ng-content />
  </div>
</mat-expansion-panel>
