import { CommonModule } from '@angular/common';
import { Component, computed, effect, input, output, viewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FilterComponent } from '@components/filters/filter/filter.component';
import { ScrollableBorderedContainerComponent } from '@components/scrollable-bordered-container/scrollable-bordered-container.component';
import { authFeature } from '@features/auth/shared/store/auth.feature';
import { TrajectoryService } from '@features/sensor-trajectories/services/trajectory/trajectory.service';
import { TrendType } from '@features/sensor-trajectories/shared/interfaces/trajectory-trend.interface';
import {
  isPeakTrajectory,
  Trajectory,
  TrajectoryType,
} from '@features/sensor-trajectories/shared/interfaces/trajectory.interface';
import { sensorTrajectoriesFeature } from '@features/sensor-trajectories/shared/store/sensor-trajectories.feature';
import { SensorId } from '@features/sensors/shared/interfaces/sensor.interface';
import { MaterialModule } from '@modules/material.module';
import { Store } from '@ngrx/store';
import { DialogService } from '@services/dialog.service';
import { FilterDialogService } from '@services/filter-dialog.service';
import { parseComputingStatus } from '@shared/interfaces/processing-status';

import { FormsModule } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { Guid } from '@astrion-webtools/graph';
import { OptionFilterComponent } from '@components/filters/filter/option-filter.component';
import { FilterModel, OptionFilterModel, OptionModel } from '@shared/interfaces/filter-model';
import { TrendMiniatureComponent } from '../trend-miniature/trend-miniature.component';
import { SensorTrajectoriesStore } from './trajectory-selection.store';

@Component({
  selector: 'app-sensor-trajectories',
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    ScrollableBorderedContainerComponent,
    TrendMiniatureComponent,
    FilterComponent,
    OptionFilterComponent,
  ],
  providers: [TrajectoryService, DialogService, FilterDialogService],
  templateUrl: './sensor-trajectories.component.html',
  styles: ``,
})
export class SensorTrajectoriesComponent {
  TrajectoryType = TrajectoryType;

  sensorId = input.required<SensorId>();
  navigateToGraph = output();
  matSort = viewChild(MatSort);

  filteredTrajectories = this.trajectoriesStore.filteredTrajectories;
  trajectoryType = this.trajectoriesStore.selectedTrajectoryType;
  hasPeakTrajectories = this.trajectoriesStore.hasPeakTrajectories;
  hasHarmonicTrajectories = this.trajectoriesStore.hasHarmonicTrajectories;
  selected = this.trajectoriesStore.selected;
  hasSelected = this.trajectoriesStore.hasSelected;
  filtersValues = this.trajectoriesStore.filtersValues;
  allSelectedTrends = this.trajectoriesStore.allSelectedTrends;
  filters = this.trajectoriesStore.filters;
  trendsSelectionState = this.trajectoriesStore.trendsSelectionState;

  constructor(
    private trajectoryService: TrajectoryService,
    private store: Store,
    private dialogService: DialogService,
    private trajectoriesStore: SensorTrajectoriesStore
  ) {
    effect(() => {
      this.refresh();
    });
  }

  hasTrajectoryTypeChoice = computed(() => this.hasPeakTrajectories() && this.hasHarmonicTrajectories());

  canRecompute = this.store.selectSignal(authFeature.selectCanRecompute);
  sensorTrajectoriesNextComputationDate = this.store.selectSignal(sensorTrajectoriesFeature.selectNextComputationDate);
  statusItemDescription = computed(() =>
    parseComputingStatus(this.sensorTrajectoriesStatus(), this.sensorTrajectoriesMessage())
  );

  dataSource = computed(() => {
    const filteredTrajectories = this.filteredTrajectories();
    if (filteredTrajectories) {
      const dataSource = new MatTableDataSource(filteredTrajectories);
      dataSource.paginator = this.paginator() ?? null;
      dataSource.sort = this.matSort() ?? null;
      return dataSource;
    }
    return undefined;
  });

  columns = computed(() => {
    const columns = ['name', 'frequency', 'detectionPercentage', 'frequencyMiniature', 'energyMiniature'];
    switch (this.trajectoryType()) {
      case TrajectoryType.PeakTrajectory:
        break;
      case TrajectoryType.HarmonicTrajectory:
        columns.push(
          'harmonicsCountMiniature',
          'averageHarmonicEnergyMiniature',
          'regularityMiniature',
          'thdMiniature'
        );
        break;
    }
    columns.push('filler');
    return columns;
  });

  getFiltersValues = (property: 'frequency' | 'detectionPercentage') => this.filtersValues()?.get(property) ?? [];

  nameOptions: OptionModel[] = [
    { id: 1, name: 'With label' },
    { id: 2, name: 'Without label' },
  ];

  private sensorTrajectoriesStatus = this.store.selectSignal(sensorTrajectoriesFeature.selectComputationStatus);
  private sensorTrajectoriesMessage = this.store.selectSignal(sensorTrajectoriesFeature.selectComputationMessage);
  private paginator = viewChild(MatPaginator);

  setTrajectoryType(event: MatRadioChange) {
    this.trajectoriesStore.setTrajectoryType(event.value as TrajectoryType);
  }

  toggle(trendType: TrendType, trajectory: Trajectory) {
    this.trajectoriesStore.toggle(trendType, trajectory);
  }

  isSelected(trendType: TrendType, id: Guid): boolean {
    return this.selected()?.get(trendType)?.has(id) ?? false;
  }

  isVisualized(trajectory: Trajectory, trendType: TrendType) {
    return this.trajectoriesStore.isVisualized(trendType, trajectory);
  }

  refresh() {
    this.trajectoryService.fetchTrajectories(this.sensorId());
  }

  async recompute() {
    const recompute = await this.dialogService.confirm({
      title: 'Recompute Trajectories',
      descriptions: ['Do you want to recompute all trajectories?', 'It might take some time.'],
    });

    if (recompute) {
      this.trajectoryService.setCurrentSensorTrackingAsDirty(this.sensorId());
    }
  }

  visualize() {
    const sensorId = this.sensorId();
    this.allSelectedTrends()?.forEach(trend => {
      this.trajectoryService.visualizeTrend(sensorId, trend.trendType, trend.trajectory);
    });
    this.trajectoriesStore.unselectAll();
    this.navigateToGraph.emit();
  }

  trendTypeCheckboxChange(trendType: TrendType, checked: boolean) {
    if (checked) {
      this.trajectoriesStore.selectAllTrend(trendType);
    } else {
      this.trajectoriesStore.unselectAllTrend(trendType);
    }
  }

  getTrendContentPath(trajectory: Trajectory, trendType: TrendType): string {
    if (isPeakTrajectory(trajectory)) {
      return this.trajectoryService.getPeakTrendContentPath(trajectory, trendType);
    } else {
      return this.trajectoryService.getHarmonicTrendContentPath(trajectory, trendType);
    }
  }

  filterFrequency(filter: FilterModel | undefined) {
    this.trajectoriesStore.setFrequencyFilter(filter);
  }

  filterDetectionPercentage(filter: FilterModel | undefined) {
    this.trajectoriesStore.setDetectionPercentageFilter(filter);
  }

  filterName(filter: OptionFilterModel | undefined) {
    this.trajectoriesStore.setNameFilter(filter);
  }

  TrendType = TrendType;
}
