export enum ValidationStepStatus {
  Unknown,
  Success,
  Warning,
  Failure,
  Neutral,
}

export enum ValidationStepStatusDto {
  Unknown,
  Success,
  Warning,
  Failure,
  Neutral,
}

const statusColor: Record<ValidationStepStatus, string> = {
  [ValidationStepStatus.Unknown]: '#e0e0e0',
  [ValidationStepStatus.Success]: 'bg-success',
  [ValidationStepStatus.Warning]: 'bg-warning',
  [ValidationStepStatus.Failure]: 'bg-error',
  [ValidationStepStatus.Neutral]: 'bg-neutral',
};

const statusColorTransparent: Record<ValidationStepStatus, string> = {
  [ValidationStepStatus.Unknown]: '#e0e0e0',
  [ValidationStepStatus.Success]: 'bg-badge-success',
  [ValidationStepStatus.Warning]: 'bg-badge-warning',
  [ValidationStepStatus.Failure]: 'bg-badge-error',
  [ValidationStepStatus.Neutral]: 'bg-badge-neutral',
};

const statusTextColor: Record<ValidationStepStatus, string> = {
  [ValidationStepStatus.Unknown]: 'text-white',
  [ValidationStepStatus.Success]: 'text-success',
  [ValidationStepStatus.Warning]: 'text-warning',
  [ValidationStepStatus.Failure]: 'text-error',
  [ValidationStepStatus.Neutral]: 'text-neutral',
};

export function getValidationStepStatusColorTransparent(status: ValidationStepStatus | undefined) {
  return statusColorTransparent[status ?? ValidationStepStatus.Unknown];
}

export function getValidationStepStatusColor(status: ValidationStepStatus | undefined) {
  return statusColor[status ?? ValidationStepStatus.Unknown];
}

export function getValidationStepStatusTextColor(status: ValidationStepStatus | undefined) {
  return statusTextColor[status ?? ValidationStepStatus.Unknown];
}
