import { inject } from '@angular/core';
import { CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { first, map } from 'rxjs';
import { Project } from './shared/interfaces/project';
import { AuthActions } from './shared/store/auth.actions';
import { authFeature } from './shared/store/auth.feature';

export const projectChangeInUrlGuardFunction: CanActivateFn = (_, state: RouterStateSnapshot) => {
  const store = inject(Store);

  return store.select(authFeature.selectChosenProject).pipe(
    first(),
    map((project: Project | null) => {
      if (state.url.length > 1 && state.url.startsWith(`/`)) {
        const projectName = state.url.split(`/`)[1];
        if (projectName !== project?.name) {
          const projects = store.selectSignal(authFeature.selectProjects);
          const project = projects().find(p => p.name === projectName);
          if (project) {
            store.dispatch(AuthActions.projectChangeRequested({ project: project }));
          } else {
            // first part of URL is not a project, we force to choose
            store.dispatch(AuthActions.projectChangeRequested({ project: null }));
          }
        }
      }
      return true;
    })
  );
};
