<div
  matRipple
  class="flex h-full w-full cursor-pointer flex-row bg-slate-200 bg-opacity-25 p-2 opacity-0 hover:opacity-100"
  [style.alignItems]="align()"
  [style.justifyContent]="justify()"
  [matTooltip]="tooltip()"
  matTooltipShowDelay="400"
  matTooltipPosition="after"
  (click)="onClick($event)">
  <mat-icon class="text-slate-500">{{ icon() }}</mat-icon>
</div>
