import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CsvExportOptions, GlGraphComponent, ImageExportOptions } from '@astrion-webtools/graph';
import { GraphCsvExportDialogComponent } from '@components/graph-csv-export-dialog/graph-csv-export-dialog.component';
import { GraphImageExportDialogComponent } from '@components/graph-image-export-dialog/graph-image-export-dialog.component';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GraphExportService {
  private prefix = 'graph-export';

  constructor(private exportDialog: MatDialog) {}

  openImageExportDialog(
    graph: GlGraphComponent,
    componentOptions?: Partial<ImageExportOptions>
  ): Promise<Blob | undefined> {
    return firstValueFrom(
      this.exportDialog
        .open(GraphImageExportDialogComponent, {
          restoreFocus: false,
          role: 'dialog',
          autoFocus: '.focused',
          height: '80vh',
          width: '50vw',
          data: {
            graph,
            componentOptions,
          },
        })
        .afterClosed()
    );
  }

  askForCsvExportOptions(xTitle: string): Promise<CsvExportOptions | undefined> {
    return firstValueFrom(
      this.exportDialog.open(GraphCsvExportDialogComponent, { restoreFocus: false, data: { xTitle } }).afterClosed()
    );
  }

  getDefaultOptions(): ImageExportOptions {
    const defaultStr = localStorage.getItem(`${this.prefix}-image-default`);
    if (defaultStr) {
      return JSON.parse(defaultStr) as ImageExportOptions;
    } else {
      const init: ImageExportOptions = {
        title: '',
        width: 1280,
        height: 720,
        titleFont: '45px Arial',
        axisFont: '25px Arial',
        ticksFont: '18px Arial',
      };
      this.saveDefaultOptions(init);
      return init;
    }
  }

  getGraphOptions(graphId: string): Partial<ImageExportOptions> {
    return JSON.parse(
      localStorage.getItem(`${this.prefix}-image-graph-${graphId}`) ?? '{}'
    ) as Partial<ImageExportOptions>;
  }

  updateDefaultOptions(options: Partial<ImageExportOptions>): ImageExportOptions {
    const newOptions: ImageExportOptions = {
      ...this.getDefaultOptions(),
      ...options,
    };
    this.saveDefaultOptions(newOptions);
    return newOptions;
  }

  updateGraphOptions(graphId: string, options: Partial<ImageExportOptions>) {
    const graphOptions = {
      ...this.getGraphOptions(graphId),
      ...options,
    };
    this.saveGraphOptions(graphId, graphOptions);
    return graphOptions;
  }

  deleteGraphOptions(graphId: string, options: string[] | string): Partial<ImageExportOptions> {
    const optionsSet = new Set(Array.isArray(options) ? options : [options]);
    const graphOptions = Object.fromEntries(
      Object.entries(this.getGraphOptions(graphId)).filter(([option]) => !optionsSet.has(option))
    ) as Partial<ImageExportOptions>;
    this.saveGraphOptions(graphId, graphOptions);
    return graphOptions;
  }

  private saveDefaultOptions(options: ImageExportOptions) {
    localStorage.setItem(`${this.prefix}-image-default`, JSON.stringify(options));
  }

  private saveGraphOptions(graphId: string, options: Partial<ImageExportOptions>) {
    localStorage.setItem(`${this.prefix}-image-graph-${graphId}`, JSON.stringify(options));
  }
}
