import { Component, computed, input } from '@angular/core';
import { GlGraphComponent, GraphCurve, GraphInput, Line, LineType } from '@astrion-webtools/graph';
import { ASTRION_INDEXEDDB_NAME, ASTRION_INDEXEDDB_TABLES } from '@shared/constants/astrion-indexeddb';

@Component({
  selector: 'app-stationarity-variance',
  imports: [GlGraphComponent],
  templateUrl: './stationarity-variance.component.html',
})
export class StationarityVarianceComponent {
  kappa = input.required<number>();
  variance = input.required<GraphCurve | undefined>();
  lines = computed<Line[]>(() => [
    {
      type: LineType.Vertical,
      value: this.kappa(),
      color: '#379612',
    },
  ]);
  data = computed<GraphInput>(() => {
    const curves: GraphCurve[] = [];
    const variance = this.variance();

    if (variance != undefined) {
      curves.push(variance);
    }

    return {
      dbName: ASTRION_INDEXEDDB_NAME,
      storeName: ASTRION_INDEXEDDB_TABLES.stationarityTestVariance,
      curves,
    };
  });
}
